import React, { FC, useCallback, useEffect, useRef, useState, FormEvent, Suspense } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import HTMLReactParser from 'html-react-parser';
import './plans-view.scss';

import useUserData from '../../hooks/use-user-data.js';
import usePharmacyData from '../../hooks/use-pharmacy-data.js';
import useUserPlanSelector from '../../hooks/use-user-plan-selector.js';
import useDrugPlanSelector from '../../hooks/use-drug-selector.js';
import useErrorStates from '../../hooks/use-error-states.js';
import DisclaimerFootNotes from './disclaimer-foot-notes.js';
import useAnalyticsData from '../../hooks/use-analytics-data.js';

import PlanCard from './plan-card.js';
import CompareBox from './compare-box.js';
import EnrollModal from './enroll-modal.js';
import ShoppingWizard from '../shopping-wizard/shopping-wizard.js';

import { isMobile } from '@cigna/dcws_leaf-web-components/utilities/is-mobile.js';
import { customTagEvent } from '../../utils/analytics/utils.js';
import { sanitize } from '../../utils/sanitize.js';
import { Md5 } from 'ts-md5';
import { zipLookUp } from '../../utils/api/zip-county-search.js';
import { planSearchMPALL } from '../../utils/apiHelper/plans-view.js';
import { memMeddsupSearchApi, memMeddsupSearchRate } from '../../utils/memoize/memPlansView.js';
import { myProviders, environment } from '../../app.js';
import { providerdatapull, providerdataedit } from '../../utils/api/providers.js';
import enOnlyContent from '../../../public/locales/en-only-content.json';

import {
  IPlanArrayResponse,
  IMedicarePlan,
  IMedicarePlanRider,
  IProduct,
  IProductSearchResponse,
  IProductStructure,
  IProductStructureIdentifier,
  IProvidersResponse,
  IProvider,
  IPlanDetailResponse,
} from '../../utils/api/interfaces.js';
import { IUserSession } from '../../context/user-context.js';
import { ISearchParams, getParams, updateQueryString } from '../../url.js';
import { convertDateToUSFormat } from '../../utils/date.js';
import { IAnalytics } from '../../context/analytics-context.js';

interface MyState {
  isMALoading: boolean;
  isPDPLoading: boolean;
  isSuppLoading: boolean;
  isSuppPlansFetching: boolean;
  charter: any;
  isNoApproval: boolean;
  isSellApproval: boolean;
  isIgseApproval: boolean;
  comparePlanInView: boolean;
  displayAgeEligibleFootnote: boolean;
  isEligibleBefore: boolean;
  suppQuestionShown: boolean;
  isSuppFormSubmitted: boolean;
  suppFormData?: {
    'first-name': string;
    'last-name': string;
    gender: string;
    birthday: string;
  };
  suppPlanArray: Object[];
  supPlanArrayresponse: IProductSearchResponse;
  planArrayResponse: Array<IPlanArrayResponse>;
  maPlanArray: Array<IMedicarePlan>;
  pdpPlanArray: Array<IMedicarePlan>;
  selectedPlan: Object;
  selectedPlanName: string;
  ratesObj?: any;
  monthlyMultiplier: number;
  lowestRateObj?: any;
  selectedId?: any;
  selectedPlanId?: any;
  selectedContractId?: any;
  selectedSegmentId?: any;
  selectedPlanType?: any;
  dataCustom?: any;
  dataImpression?: any;
  allRates?: any;
  riderArray?: Array<IMedicarePlanRider>;
  isExpired: boolean;
  selectedProvider: string;
  selectedProviderID: string;
  ogSuppPlanArray: Array<IMedicarePlan>;
  stateApprovals?: any;
  provInfo: any;
  formError?: string;
  isRenderingError?: boolean;
  showSuppEnrollSpinner?: boolean;
  allPlansFetched: boolean;
}

interface PlansViewProps {}

const PlansView: FC<PlansViewProps> = () => {
  const routeLocation = useLocation();
  const navigate = useNavigate();
  const compareContent = useTranslation('compare-plan-content');
  const errorContent = useTranslation('error-messages');
  const planResultsContent = useTranslation('plan-results-content');
  const stateApprovals = useTranslation('supp-state-approvals');
  const suppContent = useTranslation('plan-results-supp-content');
  const changeLocationContent = useTranslation('change-location');
  const userData = useUserData();
  const analyticsData = useAnalyticsData();
  const pharmacyData = usePharmacyData();
  const mySelectedPlans = useUserPlanSelector();
  const mydrugselector = useDrugPlanSelector();
  const hasErrorState = useErrorStates();
  const [state, setState] = useState<MyState>({
    isMALoading: true,
    isPDPLoading: true,
    isSuppLoading: true,
    isSuppPlansFetching: false,
    planArrayResponse: [],
    charter: '',
    isNoApproval: false,
    isSellApproval: false,
    isIgseApproval: false,
    comparePlanInView: false,
    displayAgeEligibleFootnote: false,
    isEligibleBefore: false,
    isExpired: false,
    suppQuestionShown: true,
    isSuppFormSubmitted: false,
    ogSuppPlanArray: [],
    maPlanArray: [],
    pdpPlanArray: [],
    selectedProvider: '',
    selectedProviderID: '',
    suppPlanArray: [],
    supPlanArrayresponse: { products: [] },
    selectedPlan: {},
    selectedPlanName: '',
    monthlyMultiplier: 0,
    provInfo: [],
    showSuppEnrollSpinner: false,
    allPlansFetched: false,
  });

  const [pdpsort, setpdpsort] = useState('premium-low');
  const [masort, setmasort] = useState('PlanName-low');

  let rootRef = useRef<HTMLDivElement>(null);

  const currentYear = new Date().getFullYear();
  const hasOutage = errorContent.t('show.hasOutage');
  const resultsIntro = suppContent.t('misc.resultsIntro').split('${resultsIntroLink}');
  const alertSuccessMessage =
    state.suppPlanArray.length === 1
      ? suppContent.t('misc.successMessage')
      : suppContent.t('misc.successMessagePlural');

  const sortMethods: { [key: string]: (a: IMedicarePlan, b: IMedicarePlan) => number } = {
    'premium-low': (a, b) =>
      a['MedicalPremium'] + a['DrugPremium'] > b['MedicalPremium'] + b['DrugPremium']
        ? 1
        : a['MedicalPremium'] + a['DrugPremium'] < b['MedicalPremium'] + b['DrugPremium']
          ? -1
          : 0,
    'total-low': (a, b) =>
      a['MedicalPremium'] + a['DrugPremium'] + a['EstimatedAnnualDrugCost'] >
      b['MedicalPremium'] + b['DrugPremium'] + b['EstimatedAnnualDrugCost']
        ? 1
        : a['MedicalPremium'] + a['DrugPremium'] + a['EstimatedAnnualDrugCost'] <
            b['MedicalPremium'] + b['DrugPremium'] + b['EstimatedAnnualDrugCost']
          ? -1
          : 0,
    'PlanRating-high': (a, b) => (a['PlanRating'] > b['PlanRating'] ? -1 : a['PlanRating'] < b['PlanRating'] ? 1 : 0),
    'PlanName-low': (a, b) => a['PlanName'].localeCompare(b['PlanName'], undefined, { sensitivity: 'base' }),
  };

  const setYear = (year: number) => {
    userData.setYear(year);
    const updateAnalytics: IAnalytics = {
      ...analyticsData,
      currentPageTitle: planResultsContent.t('misc.pageTitle'),
      year: year,
      currentView: 'plansView',
    };
    analyticsData.setStatus(updateAnalytics);
    const currentParams = getParams(window.location.search);
    const sanitizedParams = JSON.parse(sanitize(JSON.stringify({ ...currentParams, PlanYear: year.toString() })));
    const qs = updateQueryString(sanitizedParams, window.location);
    window.history.replaceState('', '', `?${qs}${window.location.hash}`);
    mySelectedPlans.setCompare(undefined);
  };

  const setYearfromEvent = (event: Event) => {
    const year = (event as CustomEvent).detail.year;
    setYear(year);
  };

  const tabsClick = (e: Event) => {
    // Hide .supp-form-success alert if switch to a different tab
    const alert: HTMLElement = rootRef.current?.querySelector('.supp-form-success') as HTMLElement;
    if (alert) {
      alert.setAttribute('hidden', 'true');
    }
    const tabLabel = (e.target as HTMLDivElement).getAttribute('label');
    userData.setLastTabVisit(tabLabel);
    updateCardHeight();
    updateCardPremiumHeight();
    updateFooterInfo();
  };

  const updateCardHeight = async () => {
    if (!isMobile()) {
      const tabsection = document?.querySelector('leaf-tab-section[active]');
      let maxHeight = 0;
      if (tabsection?.querySelectorAll('chc-igse-product-card').length) {
        await tabsection?.querySelector('chc-igse-product-card')?.updateComplete;
        tabsection?.querySelectorAll('.plan-card-wrapper').forEach((card) => {
          const topContentHeader = card
            .querySelector('chc-igse-product-card')
            ?.shadowRoot?.querySelector('.chc-c-igse-product-card--header') as HTMLElement;
          let thisHeight = topContentHeader.offsetHeight;
          if (topContentHeader?.offsetHeight) {
            thisHeight = topContentHeader?.offsetHeight as number;
          }
          if (thisHeight > maxHeight) {
            maxHeight = thisHeight;
          }
        });
        tabsection?.querySelectorAll('.plan-card-wrapper').forEach((card) => {
          const topContent = card
            .querySelector('chc-igse-product-card')
            ?.shadowRoot?.querySelector('.chc-c-igse-product-card--header');
          if (topContent && maxHeight !== 0) {
            (topContent as HTMLElement).style.height = `${String(maxHeight)}px`;
          }
        });
      }
    }
  };

  const updateCardPremiumHeight = () => {
    if (!isMobile()) {
      const tabsection = document?.querySelector('leaf-tab-section[active]');
      let maxHeight = 0;
      tabsection?.querySelectorAll('.plan-card-wrapper').forEach((card) => {
        const premiumSection = card
          .querySelector('chc-igse-product-card')
          ?.shadowRoot?.querySelector('.chc-c-igse-product-card--cta-wrapper') as HTMLElement;
        const cardHeight = premiumSection.offsetHeight;
        let thisHeight = cardHeight;
        if (cardHeight) {
          thisHeight = cardHeight as number;
        }
        if (thisHeight > maxHeight) {
          maxHeight = thisHeight;
        }
      });
      tabsection?.querySelectorAll('.plan-card-wrapper').forEach((card) => {
        const premiumSection = card
          .querySelector('chc-igse-product-card')
          ?.querySelector('.premium-section') as HTMLElement;
        if (premiumSection && maxHeight !== 0) {
          (premiumSection as HTMLElement).style.height = `${String(maxHeight)}px`;
        }
      });
    }
  };

  const suppPlanLength = useCallback(() => {
    if (state.suppPlanArray.length > 0) {
      return state.suppPlanArray.length;
    } else if (state.supPlanArrayresponse && state.supPlanArrayresponse.products?.length > 0) {
      return state.supPlanArrayresponse.products?.length;
    } else {
      return 0;
    }
  }, [state.suppPlanArray, state.supPlanArrayresponse]);

  const updateFooterInfo = () => {
    const activeTab = document?.querySelector('leaf-tab-section[active]');
    let footerView;
    if (activeTab !== null && activeTab!.getAttribute('label') === 'supplemental') {
      if (state.suppQuestionShown) {
        footerView = 'igse-plans-view-supplemental-form';
      } else {
        footerView = 'igse-plans-view-supplemental-plans';
      }
    } else {
      footerView = 'igse-plans-view';
    }
    userData.setActiveTabView(footerView);
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    const formData = new FormData(event.target as HTMLFormElement);
    const suppFormData = Object.fromEntries(formData) as MyState['suppFormData'];
    const today: Date = new Date();
    if (suppFormData) {
      const dob: Date = new Date(suppFormData.birthday as string);
      const [month, day, year] = [
        String(dob.getMonth() + 1).padStart(2, '0'),
        String(dob.getDate()).padStart(2, '0'),
        String(dob.getFullYear()),
      ];
      const dobLong = `${year}-${month}-${day}`;

      //@ts-ignore NEED REASON
      const age: number = new Date(today - dob).getFullYear() - 1970;
      const minDate = new Date('1955-01-01');
      const continueToPlans = () => {
        userData.setAge(age);
        userData.setDOB(dobLong);
        userData.setGender(suppFormData.gender);
        userData.setFirstName(suppFormData['first-name']);
        userData.setLastName(suppFormData['last-name']);
        setState((prevState) => ({
          ...prevState,
          suppFormData,
          suppQuestionShown: false,
          isSuppFormSubmitted: true,
          formError: undefined,
          isRenderingError: false,
          isEligibleBefore: dob.valueOf() < minDate.valueOf(),
        }));
      };
      if (age === 64) {
        const lastBirthday = new Date(dob.getFullYear() + age, dob.getMonth(), dob.getDate());
        const millisecondsPerDay = 24 * 60 * 60 * 1000;
        const ageDays = Math.floor((today.getTime() - lastBirthday.getTime()) / millisecondsPerDay + 1);
        if (ageDays >= 183) {
          continueToPlans();
        } else {
          setState((prevState) => ({
            ...prevState,
            isSuppPlansFetching: false,
            formError: suppContent.t('form.errorDobAge'),
            isRenderingError: true,
          }));
        }
      } else if (age >= 99) {
        setState((prevState) => ({
          ...prevState,
          isSuppPlansFetching: false,
          formError: suppContent.t('form.errorDobAgeMax'),
          isRenderingError: true,
        }));
      } else if (age >= 65) {
        continueToPlans();
      } else {
        setState((prevState) => ({
          ...prevState,
          isSuppPlansFetching: false,
          formError: suppContent.t('form.errorDobAge'),
          isRenderingError: true,
        }));
      }
    }
  };

  const viewSuppQuestions = () => {
    setState((prevState) => ({ ...prevState, suppQuestionShown: true }));
  };

  const viewSuppPlans = async () => {
    await suppCallAPI();
    const alert: HTMLElement = document?.querySelector('.supp-form-success') as HTMLElement;
    const activeTab = rootRef.current?.querySelector(`leaf-tab-item[active]`);
    alert.setAttribute('hidden', 'true');
    if (alert?.hidden && activeTab?.getAttribute('label') === 'supplemental') {
      alert.removeAttribute('hidden');
    } else {
      alert.setAttribute('hidden', 'true');
    }

    setImpressionSupplemental();
  };

  const requestDropdownUpdate = () => {
    const pharmacySelects = document!.querySelectorAll('leaf-dropdown[name="pharmacy-select"]');
    pharmacySelects.forEach(async (dropdown) => {
      await (dropdown as any).requestUpdate();
      dropdown.dispatchEvent(new CustomEvent('updatechildren', { bubbles: true, composed: true, cancelable: true }));
    });
  };

  const returnSearch = () => {
    if (!state.comparePlanInView) {
      requestDropdownUpdate();

      (document.querySelector('igse-app')?.shadowRoot?.querySelector('#igse-focus-anchor') as HTMLElement)?.focus();

      window.scrollTo(0, 0);
    } else {
      document.title = `${compareContent.t('misc.pageTitle')} | Cigna Healthcare`;
      window.digitalData.title = enOnlyContent.comparePlansView.pageTitle;
    }
  };

  const passEnroll = (event: Event) => {
    const enrollDetails = (event as CustomEvent).detail;
    setState((prevState) => ({
      ...prevState,
      selectedContractId: enrollDetails.selectedContractId,
      selectedId: enrollDetails.selectedId,
      selectedPlanId: enrollDetails.selectedPlanId,
      selectedPlanName: enrollDetails.selectedPlanName,
      selectedSegmentId: enrollDetails.selectedSegmentId,
      dataCustom: enrollDetails.dataCustom,
      dataImpression: enrollDetails.dataImpression,
      selectedPlanType: enrollDetails.selectedPlanType,
      isExpired: enrollDetails.isExpired,
    }));
  };

  const fireTagEvent = () => {
    customTagEvent(rootRef.current as HTMLElement, 'supplemental');
  };

  const setImpressionSupplemental = () => {
    if (
      document?.querySelector(`.plan-card-wrapper[data-plantype="supp"]`) !== null &&
      !state.isSuppLoading &&
      document?.querySelector('leaf-tab-section[active]')?.getAttribute('label') === 'supplemental'
    ) {
      setImpressionPlanView(true);
      fireTagEvent();
    } else setTimeout(() => setImpressionSupplemental(), 300);
  };

  const setImpressionPageView = () => {
    if (document?.querySelectorAll('leaf-tab-item').length && !state.isSuppLoading) {
      analyticsData.setImpressionArr([...(document?.querySelectorAll('leaf-tab-item') as unknown as Array<Element>)]);
      analyticsData.setImpressionType('plansViewTabs');
    } else {
      setTimeout(() => {
        setImpressionPageView();
      }, 500);
    }
  };

  //TODO: add this back for analytics
  const setImpressionPlanView = (isSupp = false) => {
    const cards = document?.querySelector('leaf-tab-section[active]')?.querySelectorAll('chc-igse-product-card');
    if (cards && cards?.length > 0) {
      const arr: Element[] = [];
      cards?.forEach((card, index) => {
        let newImpression = card?.getAttribute('data-impression')?.replace('^^^', `^${index + 1}^`);
        if (!isSupp) {
          const premium = (card?.querySelector('.number') as HTMLElement)?.innerText?.replace('$', '');
          newImpression = newImpression?.replace('premiumCost', premium);
        }
        card?.setAttribute('data-impression', newImpression || '');
        arr.push(card);
      });
      const updateAnalytics: IAnalytics = {
        ...analyticsData,
        impressionArr: [...arr],
        providerArr: myProviders.data.providers,
        impressionType: 'plansViewProducts',
      };
      analyticsData.impressionItemsArr = [];
      analyticsData.impressionArr = [...arr];
      analyticsData.setStatus(updateAnalytics);
    } else {
      setTimeout(() => {
        setImpressionPlanView(isSupp);
      }, 1000);
    }
  };

  const setImpressionPlanViewTab = (event: Event) => {
    const selectedTab = (event!.currentTarget as HTMLElement)!.getAttribute('label');
    if (!(event.currentTarget as HTMLElement).hasAttribute('name')) mySelectedPlans.setCompare(undefined);
    customTagEvent(document?.querySelector('#plans-view') as HTMLElement, `${selectedTab}-social-click` || '');
    customTagEvent(rootRef.current as HTMLElement, (event!.currentTarget as HTMLElement)!.getAttribute('label') || '');
    if (selectedTab !== 'supplemental' || state.suppPlanArray?.length > 0)
      setImpressionPlanView((event!.currentTarget as HTMLElement)!.getAttribute('label') === 'supplemental');
    if (selectedTab === 'supplemental' && state.allPlansFetched) openOutageModal();
  };

  const goToPharm = () => {
    const urlObj = new URL(window.location.href);
    urlObj.pathname = '/pharmacy/';

    const path = urlObj.href.replace(urlObj.origin, '');
    navigate(path, { state: { pageNumber: 1, from: 'planView' } });
  };

  const showExtraHelp = () => {
    const urlObj = new URL(window.location.href);
    urlObj.pathname = '/extra-help';

    const path = urlObj.href.replace(urlObj.origin, '');
    const pathName = window.location.href.replace(window.location.origin, '');
    navigate(path, { state: { pageNumber: 1, pathname: pathName } });
  };

  const renderExtraHelp = () => {
    if (userData.subsidyLevel) {
      let renderExtraHelp: any;
      if (userData.subsidyLevel) {
        switch (userData.subsidyLevel) {
          case '1':
            renderExtraHelp = `${planResultsContent.t('misc.extraHelpMedicaid')}`;
            break;
          case '2':
            renderExtraHelp = `${planResultsContent.t('misc.extraHelpSsi')}`;
            break;
          case '3':
            if (userData.subsidyPercent)
              renderExtraHelp = planResultsContent
                .t('misc.extraHelpSS')
                .replace('${percent}', String(userData.subsidyPercent));
            break;
          case '4':
            renderExtraHelp = planResultsContent.t('misc.extraHelpNone');
            break;
          case '5':
            renderExtraHelp = planResultsContent.t('misc.extraHelpMsp');
            break;
          default:
            renderExtraHelp = planResultsContent.t('misc.extraHelpNone');
            break;
        }
      }
      return (
        <>
          <h6>{planResultsContent.t('misc.extraHelpHeader')}</h6>
          <p className="extrahelp-selection">{renderExtraHelp}</p>
          <leaf-button-link onClick={() => showExtraHelp()}>
            {planResultsContent.t('misc.extraHelpLink')}
          </leaf-button-link>
        </>
      );
    } else {
      const [before, after] = planResultsContent.t('misc.extraHelpPlaceholder').split('${extraHelpLink}');
      return (
        <p className="extrahelp-placeholder">
          {before}
          <leaf-button-link onClick={() => showExtraHelp()}>
            {planResultsContent.t('misc.extraHelpPlaceholderLink')}
          </leaf-button-link>
          {after}
        </p>
      );
    }
  };

  const renderPharmacyDropdown = () => {
    if (pharmacyData.pharmacies && pharmacyData.pharmacies.length > 0) {
      const firstDropdownItemProps =
        pharmacyData.selectedPharmacyName === 'Mail Order'
          ? { value: 'Mail Order', selected: true }
          : { value: 'Mail Order' };

      return (
        <leaf-dropdown name="pharmacy-select" onFocus={setImpressionPlanViewTab}>
          <span slot="dropdown-label">{planResultsContent.t('misc.pharmacyHeader')}</span>
          <leaf-dropdown-item {...firstDropdownItemProps}>
            {HTMLReactParser(planResultsContent.t('misc.pharmacyMailOrder'))}
          </leaf-dropdown-item>
          {pharmacyData.pharmacies.map((pharmacy: { Name: any; PharmacyID: any }) => {
            const dropdownItemProps =
              pharmacyData.selectedPharmacyName === pharmacy.Name
                ? { key: pharmacy.PharmacyID, value: pharmacy.Name, selected: true }
                : { key: pharmacy.PharmacyID, value: pharmacy.Name };
            return <leaf-dropdown-item {...dropdownItemProps}>{pharmacy.Name}</leaf-dropdown-item>;
          })}
        </leaf-dropdown>
      );
    } else {
      return (
        <>
          <h6>{planResultsContent.t('misc.pharmacyHeader')}</h6>
          <p className="pharmacy-selection">{HTMLReactParser(planResultsContent.t('misc.pharmacyMailOrder'))}</p>
        </>
      );
    }
  };

  // the more i think about this function the more i think i don't even need to have this many ifs... just set query select to error name.
  const updatealert = (event: any) => {
    if (event.detail.alert === 'errorZip') {
      if (event.detail.show) {
        document!.querySelector('.errorZip')?.removeAttribute('hidden');
      } else {
        document!.querySelector('.errorZip')?.setAttribute('hidden', 'true');
      }
    }
    if (event.detail.alert === 'errorInit') {
      if (event.detail.show) {
        document!.querySelector('.errorInit')?.removeAttribute('hidden');
      } else {
        document!.querySelector('.errorInit')?.setAttribute('hidden', 'true');
      }
    }
    if (event.detail.alert === 'errorMedSup') {
      if (event.detail.show) {
        document!.querySelector('.errorMedSup')?.removeAttribute('hidden');
      } else {
        document!.querySelector('.errorMedSup')?.setAttribute('hidden', 'true');
      }
    }
    if (event.detail.alert === 'errorRateQuote') {
      if (event.detail.show) {
        document!.querySelector('.errorRateQuote')?.removeAttribute('hidden');
      } else {
        document!.querySelector('.errorRateQuote')?.setAttribute('hidden', 'true');
      }
    }
  };

  const modal = () => (
    <EnrollModal
      isExpired={state.isExpired}
      selectedPlanType={state.selectedPlanType}
      selectedPlanName={state.selectedPlanName}
      selectedId={state.selectedId}
      selectedPlanId={state.selectedPlanId}
      selectedContractId={state.selectedContractId}
      selectedSegmentId={state.selectedSegmentId}
      dataImpression={state.dataImpression}
      dataCustom={`prop13:ac24774^prop20:Plan Enrollment Confirmation^eVar119:CSTD-3126^eVar120:link-shop-plans-enrollment-confirmation-modal-confirm-exit^eVar160:exit*${environment.endpoints.drxUrl}`}
    ></EnrollModal>
  );

  const renderProviderRemoveModal = useCallback(
    () => (
      <leaf-modal has-focus-trap="true" id="removeprovider-modal" size="md" data-impression={state.dataImpression}>
        <h4 slot="heading">
          {planResultsContent.t('providerInfo.removeModalContent').replace('${providername}', state.selectedProvider)}
        </h4>
        <leaf-flex-container slot="cta">
          <leaf-button
            onClick={removeProviders}
            variant="primary"
            data-custom="prop13:ac24767^prop20:Are you sure you want to remove this Provider^eVar119:CSTD-3127^eVar120:link-shop-plans-confirm-remove-provider-team-modal-close"
          >
            {planResultsContent.t('providerInfo.yesRemoveText')}
          </leaf-button>
          <leaf-button
            onClick={dismissProviderRemoveModal}
            variant="primary"
            data-custom="prop13:ac24768^prop20:Are you sure you want to remove this Provider^eVar119:CSTD-3127^eVar120:link-shop-plans-cancel-remove-provider-team-modal-close"
          >
            {planResultsContent.t('providerInfo.noRemoveText')}
          </leaf-button>
        </leaf-flex-container>
      </leaf-modal>
    ),
    [state.selectedProvider, state.dataImpression],
  );

  const renderNoApprovalMessage = () => {
    const approvalObj: any = stateApprovals
      .t('stateData', { returnObjects: true })
      .find((el: any) => el.state === userData.location?.state);
    if (approvalObj?.sellApproved === 'FALSE') {
      return <h3>{errorContent.t('show.errorNoCover')}</h3>;
    } else {
      return (
        <>
          <h3>{suppContent.t('notApproved.noApprovalMessage')}</h3>
          <p>
            {suppContent.t('notApproved.call')}&nbsp;
            <chc-phone-number
              number={suppContent.t('notApproved.noApprovalCallNumber')}
              separator="-"
              parentheses
              campaign-opt-in
            ></chc-phone-number>
            &nbsp;{suppContent.t('notApproved.getQuoteHours')}, {suppContent.t('notApproved.tty')}
          </p>
        </>
      );
    }
  };

  const renderStateMessages = () => {
    if (userData.location?.state === 'TX' || userData.location?.state === 'NV') {
      return (
        <p>
          <strong>
            {suppContent.t('footNotes.medSuppInsuredBy')} {suppContent.t('footNotes.cic')}.
          </strong>
        </p>
      );
    } else if (userData.location?.state === 'NC') {
      return (
        <p>
          <strong>
            {suppContent.t('footNotes.insuredBy')} {suppContent.t('footNotes.cnhic')}
          </strong>
        </p>
      );
    } else if (userData.location?.state === 'NJ') {
      return (
        <p>
          <strong>
            {suppContent.t('footNotes.medSuppInsuredBy')} {suppContent.t('footNotes.cnhic')}
          </strong>
        </p>
      );
    } else if (userData.location?.state === 'AK') {
      return (
        <p>
          <strong>
            {suppContent.t('footNotes.insuredBy')} {suppContent.t('footNotes.lalic')}
          </strong>
        </p>
      );
    } else if (userData.location?.state === 'NH') {
      return (
        <p className="result-spacing">
          <strong> {suppContent.t('stateInfo.solicitation')} </strong>
        </p>
      );
    }
    return;
  };

  // cannot remove this any. mismatch between Event and CustomEvent types
  const openProviderRemoveModal = async (event: any) => {
    const modalWrapper = document?.querySelector(`#removeprovider-modal`);
    setState((prevState) => ({
      ...prevState,
      selectedProvider: event.detail.selectedProvider,
      selectedProviderID: event.detail.selectedProviderID,
    }));
    (modalWrapper as any).requestUpdate();
    (modalWrapper as any).show();
  };

  const setProviderImpression = () => {
    document
      ?.querySelector(`leaf-tab-section[active][label="ma"]`)
      ?.querySelectorAll('.plan-card-wrapper')
      .forEach((card, cardIndex) => {
        const el = card.querySelector('chc-igse-product-card');
        const impressionStr = el?.getAttribute('data-impression');
        if (myProviders.data.providers?.length) {
          let passStr = '';
          let customProductStr = '';
          myProviders.data.providers.forEach((provider: IProvider, index: number) => {
            const covered = !provider.plans
              ? false
              : provider.plans.find((plan: string) => {
                  return (impressionStr as string).indexOf(plan) > -1;
                });
            passStr =
              impressionStr?.replace('No provider given', provider.olpdProviderNetworksAddressId.toString()) || '';
            passStr = passStr.replace(
              'providerInfo',
              `${index + 1}:${provider.specialtyId}:${covered ? 'COVERED' : 'NOT COVERED'}`,
            );
            customProductStr += `${passStr}${
              index + 1 === myProviders.data.providers.length ? '' : ',;pagefeature;;;;eVar110=displayed^'
            }`;
          });
          customProductStr = customProductStr.replaceAll('^^^', `^${cardIndex + 1}^`);
          el?.setAttribute('data-product-custom', el?.getAttribute('data-product-custom') + customProductStr);
        } else {
          el?.setAttribute('data-product-custom', '');
        }
      });
  };

  const removeProviders = () => {
    provideredit();
    setTimeout(() => {
      setProviderImpression();
      setImpressionPlanView();
    }, 3000);
    dismissProviderRemoveModal();
  };

  const dismissProviderRemoveModal = () => {
    (document?.querySelector(`#removeprovider-modal`) as any)?.hide();
  };

  const updateLocation = () => {
    const alert: HTMLElement = document?.querySelector('.supp-form-success') as HTMLElement;
    if (alert) {
      alert.setAttribute('hidden', 'true');
    }

    userData.setCurrentPageTitle(changeLocationContent.t('zipSearch.pageTitle'));
    userData.setCurrentView('countyLookupView');

    const urlObj = new URL(window.location.href);
    urlObj.pathname = '/change-location';

    const path = urlObj.href.replace(urlObj.origin, '');
    navigate(path, { state: routeLocation });
  };

  const showComparePlanSection = () => {
    document.title = `${compareContent.t('misc.pageTitle')} | Cigna Healthcare`;
    window.digitalData.title = enOnlyContent.comparePlansView.pageTitle;
    window.scrollTo(0, 0);
  };

  const dropdownUpdate = (event: any, justActiveTab = true) => {
    const element = event.detail.element;
    const tab = justActiveTab
      ? (document?.querySelector('leaf-tab-section[active]') as any)
      : document?.querySelector(`leaf-tab-section[label="${element.getAttribute('name').replace('-sort', '')}"`);
    const value = (
      tab
        .querySelector('leaf-dropdown[name="pharmacy-select"]')
        ?.querySelector('leaf-dropdown-item[selected]') as HTMLSelectElement
    )?.value;
    if (element.getAttribute('name').includes('pharmacy')) {
      //Why wont find work here
      state.planArrayResponse.forEach((pharmObj: any) => {
        if (pharmObj.pharmacyName === value) {
          if (tab?.label === 'ma') {
            setState((prevState) => ({ ...prevState, maPlanArray: pharmObj.ma.MedicarePlans }));
          } else {
            setState((prevState) => ({ ...prevState, pdpPlanArray: pharmObj.pdp.MedicarePlans }));
          }
          pharmacyData.setSelectedPharmacyName(value);
        }
      });
    }
    const selecteddropdown = event.target as HTMLSelectElement;
    if (selecteddropdown.getAttribute('name') === 'ma-sort') {
      setmasort(selecteddropdown.value);
    }
    if (selecteddropdown.getAttribute('name') === 'pdp-sort') {
      setpdpsort(selecteddropdown.value);
    }
    setTimeout(() => {
      if (tab.label !== 'supplemental') setImpressionPlanView();
    }, 3000);
  };

  //this can be broken down to one api call by splitting plantype on response. and in the api calling planType=7
  const callDRXPlanAPI = async () => {
    setState((prevState) => ({
      ...prevState,
      isPDPLoading: true,
      isMALoading: true,
      maPlanArray: [],
      pdpPlanArray: [],
    }));

    try {
      const response = await planSearchMPALL({
        userData: userData,
        pharmacyList: pharmacyData.getPharmacyList(),
        dosageList: mydrugselector.dosageList,
      });

      setState((prevState) => ({ ...prevState, planArrayResponse: response }));
      let selectedPharmacyIndex = 0;
      if (pharmacyData.selectedPharmacyName) {
        selectedPharmacyIndex = response.findIndex((pharmData: IPlanDetailResponse) => {
          return pharmData.pharmacyName === pharmacyData.selectedPharmacyName;
        });
      }
      selectedPharmacyIndex = selectedPharmacyIndex != -1 ? selectedPharmacyIndex : 0;
      const maPlanArrayresponse = response[selectedPharmacyIndex].ma;
      const pdpPlanArrayresponse = response[selectedPharmacyIndex].pdp;

      // returns null - need to handle no ma plans case
      // Medicare Advantage Plans
      if (maPlanArrayresponse) {
        setState((prevState) => ({ ...prevState, isMALoading: false, maPlanArray: maPlanArrayresponse.MedicarePlans }));
      }

      // Medicare Part D Plans
      if (pdpPlanArrayresponse) {
        setState((prevState) => ({
          ...prevState,
          isPDPLoading: false,
          pdpPlanArray: pdpPlanArrayresponse.MedicarePlans,
        }));
      }

      // Approval
      const approvalObj: any = stateApprovals
        .t('stateData', { returnObjects: true })
        .find((el: any) => el.state === userData.location?.state);
      callStateApproval(approvalObj);
      setState((prevState) => ({ ...prevState, isMALoading: false, isPDPLoading: false }));
    } catch (error) {
      console.error(error);
      updatealert({
        detail: { alert: 'errorInit', show: true },
      });
    }
  };

  const callStateApproval = (approvalObj: any) => {
    const defaultState = {
      isSuppLoading: false,
      isNoApproval: false,
      isSellApproval: false,
      isIgseApproval: false,
    };
    if (approvalObj && approvalObj.enrollApproved === 'TRUE' && approvalObj.igseApproved === 'TRUE') {
      defaultState.isIgseApproval = true;
    } else if (
      approvalObj &&
      (approvalObj.sellApproved === 'FALSE' || approvalObj.sellApproved === 'TRUE') &&
      approvalObj.igseApproved === 'FALSE'
    ) {
      defaultState.isNoApproval = true;
    } else {
      defaultState.isSellApproval = true;
    }
    setState((prevState) => ({ ...prevState, ...defaultState }));
  };

  const callSuppAPI = async () => {
    try {
      await presuppCallApi();
      if (userData?.age) {
        await suppCallAPI();
      }
    } catch (error) {
      if (error === 'medsupSearch!200') {
        updatealert({
          detail: { alert: 'errorMedSup', show: true },
        });
      }
    }
  };

  const providerinit = () => {
    document.addEventListener('visibilitychange', providerpull);
  };

  //TODO: Fix this when we get the new prov search tool
  // arrow function was required to bind. "this" functions and variables.
  const providerpull = async () => {
    if (document.visibilityState === 'visible') {
      const providerdata: IProvidersResponse = await providerdatapull({ session: userData.session });
      if (providerdata.providers) {
        if (JSON.stringify(myProviders.data.providers) !== JSON.stringify(providerdata.providers))
          setTimeout(() => {
            setProviderImpression();
            setImpressionPlanView();
          }, 3000);
        if (Array.isArray(providerdata.providers)) {
          myProviders.setdata(providerdata);
          setState((prevState) => ({ ...prevState }));
        } else {
          myProviders.editproviders({ providers: [] });
        }
      } else {
        myProviders.editproviders({ providers: [] });
      }
    }
  };

  // //TODO: Fix this when we get the new prov search tool
  const provideredit = () => {
    const providerlist = myProviders.data.providers.filter(
      (provider: IProvider) => provider.olpdProviderNetworksAddressId.toString() != state.selectedProviderID,
    );
    myProviders.editproviders(providerlist);
    providerdataedit(userData.session, myProviders.data).then(() => {
      setState((prevState) => ({ ...prevState }));
    });
  };

  const presuppCallApi = async () => {
    // Medicare Supplement Plans
    // Required 'productGroup' and 'location.state' fields
    try {
      const supPlanArrayresponse = (await memMeddsupSearchApi({ userData: userData })) as IProductSearchResponse;
      if (supPlanArrayresponse?.products.length > 0) {
        const charter = supPlanArrayresponse?.products[0]?.productStructures
          .find((el: IProductStructure) => el.type === 'Financial')
          ?.identifiers.find((el: IProductStructureIdentifier) => el.type === 'legalEntity')?.value;
        setState((prevState) => ({ ...prevState, supPlanArrayresponse, charter, isSuppLoading: false }));
      } else {
        setState((prevState) => ({ ...prevState, isSuppLoading: false }));
      }
    } catch (error) {
      throw 'medsupSearch!200';
    }
  };

  const suppCallAPI = async () => {
    try {
      let newState = { ...state };

      if (state.supPlanArrayresponse.products.length > 0) {
        setState((prevState) => ({ ...prevState, isSuppPlansFetching: true }));

        await Promise.all(
          newState.supPlanArrayresponse.products.map(async (ratequoteresponse: IProduct) => {
            const rateQuoteValue = await memMeddsupSearchRate({
              productID: ratequoteresponse.productID,
              userData: userData.getUserData(),
            }).then((value) => {
              return value;
            });
            return { ...ratequoteresponse, rateQuote: rateQuoteValue };
          }),
        ).then((combinedRateQuotes: any) => {
          newState.ogSuppPlanArray = combinedRateQuotes;
          if (!state.isEligibleBefore) {
            newState.ogSuppPlanArray.forEach((plan: any) => {
              const matchingPlan = suppContent
                .t('planBenefits', { returnObjects: true })
                .find((planData: { ENONLY_apiValue: any }) => {
                  return plan.productInformation.productName.includes(planData.ENONLY_apiValue);
                });
              plan.order = matchingPlan.order;
            });
            newState.suppPlanArray = newState.ogSuppPlanArray.filter((el: any) => {
              if (
                !el.productInformation.productName.includes('PLAN F') &&
                !el.productInformation.productName.includes('HIGH DEDUCTIBLE F') &&
                !el.productInformation.productName.includes('PLAN C') &&
                !el.productInformation.productName.includes('EXTENDED PLAN MN')
              ) {
                return el;
              }
            });
          } else {
            const eligiblePlansArray = newState.ogSuppPlanArray.filter((el: any) => {
              if (
                el.productInformation.productName.includes('PLAN F') ||
                el.productInformation.productName.includes('HIGH DEDUCTIBLE F') ||
                el.productInformation.productName.includes('PLAN C') ||
                el.productInformation.productName.includes('EXTENDED PLAN MN')
              ) {
                return el;
              }
            });
            newState.displayAgeEligibleFootnote = eligiblePlansArray.length > 0;
            newState.ogSuppPlanArray.forEach((plan: any) => {
              const matchingPlan = suppContent
                .t('planBenefits', { returnObjects: true })
                .find((planData: { ENONLY_apiValue: any }) => {
                  return plan.productInformation.productName.includes(planData.ENONLY_apiValue);
                });
              plan.order = matchingPlan.order;
            });
            newState.suppPlanArray = newState.ogSuppPlanArray.filter((el: any) => {
              if (!el.productInformation.productName.includes('EXTENDED 2020')) {
                return el;
              }
            });
          }

          setState((prevState) => ({
            ...prevState,
            ...newState,
            isSuppPlansFetching: false,
            suppQuestionShown: false,
          }));
        });
      }
    } catch (e) {
      console.log(e);
      const alert: HTMLElement = document?.querySelector('.supp-form-success') as HTMLElement;
      alert.setAttribute('hidden', 'true');
      setState((prevState) => ({ ...prevState, isSuppPlansFetching: false }));
      updatealert({
        detail: { alert: 'errorRateQuote', show: true },
      });
    }
  };

  const handleSuppEnrollSpinner = () => {
    const spinnerStatus = state.showSuppEnrollSpinner;
    setState((prevState) => ({ ...prevState, showSuppEnrollSpinner: !spinnerStatus }));
  };

  const toggleActiveTabs = (activeTab: string, tabToSet: string) => {
    rootRef.current?.querySelector(`leaf-tab-item[label="${activeTab}"]`)?.removeAttribute('active');
    rootRef.current?.querySelector(`leaf-tab-section[label="${activeTab}"]`)?.removeAttribute('active');
    rootRef.current?.querySelector(`leaf-tab-item[label="${tabToSet}"]`)?.setAttribute('active', 'true');
    rootRef.current?.querySelector(`leaf-tab-section[label="${tabToSet}"]`)?.setAttribute('active', 'true');
    (
      rootRef.current
        ?.querySelector('leaf-tab[mobile-variant="dropdown"]')
        ?.shadowRoot?.querySelector('leaf-select') as HTMLSelectElement
    ).value = tabToSet;
  };

  const setTab = (
    activeTab: string,
    activeTabLength: number,
    nextTab: string,
    nextTabLength: number,
    lastTab: string,
  ) => {
    // Wrapping with if statement for now, for MA only requirements
    if (activeTab === 'ma') {
      const tabToSet = nextTabLength > 0 ? nextTab : lastTab;
      if (activeTabLength === 0) {
        toggleActiveTabs(activeTab, tabToSet);
        userData.setLastTabVisit(tabToSet);
      }
    }
  };

  const tabCheck = () => {
    switch (rootRef.current?.querySelector(`leaf-tab-item[active]`)?.getAttribute('label')) {
      case 'pdp':
        setTab('pdp', state.pdpPlanArray.length, 'ma', state.maPlanArray.length, 'supplemental');
        break;
      case 'supplemental':
        setTab('supplemental', suppPlanLength(), 'pdp', state.pdpPlanArray.length, 'ma');
        break;
      default:
        setTab('ma', state.maPlanArray.length, 'supplemental', suppPlanLength(), 'pdp');
    }
  };

  const toggleCompareSectionPlanCard = (event: Event) => {
    const activeTab = document?.querySelector('leaf-tab-section[active]')?.getAttribute('label');
    const target = (event as CustomEvent).detail.checkgroup as HTMLElement;
    const card = target.closest('chc-igse-product-card');
    if (card) {
      (card as unknown as Element).setAttribute('selected', 'true');
    }
    if (!target?.querySelector('leaf-checkbox')?.hasAttribute('checked')) {
      (card as unknown as Element)?.removeAttribute('selected');
    }
    const plan =
      activeTab === 'ma'
        ? state.maPlanArray.find(
            (plan) => plan.ID === (target.querySelector('leaf-checkbox') as HTMLInputElement)?.dataset.planid,
          )
        : state.pdpPlanArray.find(
            (plan) => plan.ID === (target.querySelector('leaf-checkbox') as HTMLInputElement)?.dataset.planid,
          );
    userData.setCompareToggle(plan);
  };

  const updateTabDropdownContent = () => {
    const tabs = rootRef.current?.querySelector('leaf-tab[mobile-variant="dropdown"]');
    if (tabs) {
      tabs.dispatchEvent(
        new CustomEvent('leaf-tabs:dropdown-change', { bubbles: true, composed: true, cancelable: true }),
      );
    }
  };

  const handleTabDropdownChange = (event: Event) => {
    const { target } = event;
    const dropdownValue = (target as HTMLSelectElement).value;
    if (dropdownValue) {
      window.location.hash = `#${dropdownValue}`;
      userData.setLastTabVisit(dropdownValue);
    }
  };

  const switchTab = () => {
    (document?.querySelector('leaf-tab-item[label="ma"]')?.shadowRoot?.querySelector('a') as HTMLElement)?.click();

    rootRef.current?.dispatchEvent(
      new CustomEvent('clearSelectedPlans', { bubbles: true, composed: true, cancelable: true }),
    );
  };

  useEffect(() => {
    requestDropdownUpdate();
  }, [pharmacyData.selectedPharmacyName]);

  useEffect(() => {
    if (state.formError && state.isRenderingError) {
      setState((prevState) => ({ ...prevState, isRenderingError: false }));
    }
  }, [state.formError, state.isRenderingError]);

  useEffect(() => {
    (async () => {
      if (
        userData.location?.zip &&
        userData.location?.fip &&
        userData.year &&
        userData.session &&
        userData.productGroup &&
        userData.location?.state
      ) {
        try {
          await callDRXPlanAPI();
        } catch (error) {
          console.error('Error fetching data from DRX Plan API', error);
        }

        try {
          await callSuppAPI();
        } catch (error) {
          console.error('Error fetching data from Supp API', error);
        }
        setState((prevState) => ({ ...prevState, allPlansFetched: true }));
      }
    })();
  }, [
    userData.location?.zip,
    userData.location?.fip,
    userData.year,
    userData.session,
    userData.productGroup,
    userData.location?.state,
  ]);

  useEffect(() => {
    if (state.suppFormData && state.supPlanArrayresponse.products.length > 0) {
      if (state.isIgseApproval) {
        viewSuppPlans();
      }
      updateFooterInfo();
    }
    updateTabDropdownContent();
  }, [state.suppFormData, state.supPlanArrayresponse]);

  useEffect(() => {
    if (!state.isSuppLoading && !state.isPDPLoading && !state.isMALoading) {
      setTimeout(() => {
        setImpressionPageView();
      }, 1000);
    } else {
      updateFooterInfo();
    }
  }, [state.isSuppLoading, state.isPDPLoading, state.isMALoading]);

  useEffect(() => {
    if (state.suppQuestionShown) {
      updateFooterInfo();
    } else if (!state.suppQuestionShown && !state.isSuppPlansFetching && state.suppPlanArray.length > 0) {
      setTimeout(() => {
        updateCardHeight();
      }, 100);
      const activeTab = rootRef.current?.querySelector(`leaf-tab[active]`);
      if (state.isSuppFormSubmitted && activeTab?.getAttribute('label') === 'supplemental') {
        (rootRef.current?.querySelector('.supp-data') as HTMLElement)?.focus();
      }
    }
  }, [state.suppQuestionShown, state.isSuppPlansFetching, state.suppPlanArray]);

  useEffect(() => {
    if (state.maPlanArray.length > 0 || state.pdpPlanArray.length > 0 || state.suppPlanArray.length > 0) {
      tabCheck();
    }
    if (hasOutage === 'TRUE' && state.allPlansFetched) openOutageModal();

    setTimeout(() => {
      updateCardHeight();
    }, 100);
  }, [state.allPlansFetched]);

  useEffect(() => {
    (async () => {
      if (
        (!userData.lastTabVisit && window.location.hash === '#ma') ||
        window.location.hash === '#supplemental' ||
        window.location.hash === '#pdp'
      ) {
        userData.setLastTabVisit(window.location.hash.replace('#', ''));
      }
      customTagEvent(
        document?.querySelector('#plans-view') as HTMLElement,
        `${window.location.hash.replace('#', '')}-social`,
      );
      if (
        userData.productGroup &&
        userData.location?.state &&
        userData.firstName &&
        userData.lastName &&
        userData.DOB &&
        userData.gender
      ) {
        const suppFormData = {
          'first-name': userData.firstName,
          'last-name': userData.lastName,
          birthday: convertDateToUSFormat(userData.DOB),
          gender: userData.gender,
        };

        setState((prevState) => ({
          ...prevState,
          suppFormData,
          isSuppPlansFetching: true,
          suppQuestionShown: false,
          isEligibleBefore: true,
          isIgseApproval: true,
        }));
      }

      const searchParams: ISearchParams = getParams(location.search);
      const sanitizedParams = JSON.parse(sanitize(JSON.stringify(searchParams)));

      rootRef.current?.addEventListener('returnSearch', returnSearch);
      rootRef.current?.addEventListener('passEnroll', passEnroll);
      rootRef.current?.addEventListener('comparePlanView', showComparePlanSection);
      rootRef.current?.addEventListener('leaf-click', tabsClick);
      document?.addEventListener('sendYear', setYearfromEvent);
      document?.addEventListener('showProviderRemoveModal', openProviderRemoveModal);
      document?.addEventListener('providerpull', providerinit);

      // showComparePlanSection();

      let planYear = sanitizedParams?.PlanYear;

      zipLookUp(sanitizedParams?.zip)
        .then((response: any) => {
          const filteredresponse: any = response.filter((code: any) => {
            if (code.CountyFIPS == userData.location?.fip) {
              return code;
            }
          })[0];
          const updateInstance: IUserSession = {
            ...userData,
            year: userData.year || planYear || 2025,
            location: {
              ...userData.location,
              zip: sanitizedParams.zip,
              fip: filteredresponse.CountyFIPS,
              county: filteredresponse.CountyName,
              state: filteredresponse.State,
            },
          };
          userData.setStatus(updateInstance);
          const updateAnalytics: IAnalytics = {
            ...analyticsData,
            activeTab: document?.querySelector('leaf-tab-section[active]')?.getAttribute('label'),
            currentView: 'plansView',
            currentPageTitle: planResultsContent.t('misc.pageTitle'),
            year: planYear || 2025,
          };
          analyticsData.setStatus(updateAnalytics);
        })
        .then(async () => {
          if (sanitizedParams?.PlanType === 'MAPD') {
            if (state.maPlanArray.length === 0) {
              const locationHash = state.pdpPlanArray.length > 0 ? 'pdp' : 'supplemental';
              if (window.location.hash !== `#${locationHash}`) {
                rootRef.current!.querySelector(`leaf-tab[label="${locationHash}"]`)?.querySelector('a')?.click();
              }
            }
          }

          if (sanitizedParams?.PlanYear) {
            planYear = sanitizedParams.PlanYear;
            if (userData.year?.toString() !== planYear) {
              userData.setYear(planYear);
              setYear(planYear);
            }
          }

          // redirects user to plan detail from cigna.com plan search page.
          if (sanitizedParams?.PlanID) {
            mySelectedPlans.setSelectedDetail(sanitizedParams.PlanID);

            const urlObj = new URL(window.location.href);
            urlObj.pathname = '/plan-details';

            const path = urlObj.href.replace(urlObj.origin, '');
            navigate(path);
          }
        })
        .catch((error) => {
          if (error === 'drxziplookup!200') {
            updatealert({
              detail: { alert: 'errorZip', show: true },
            });
            setState((prevState) => ({ ...prevState, isSuppLoading: false }));
          }
          if (error === 'DRXinitsession!200') {
            updatealert({
              detail: { alert: 'errorInit', show: true },
            });
            presuppCallApi();
            setState((prevState) => ({ ...prevState, isSuppLoading: false }));
          }
        });

      if (!pharmacyData.selectedPharmacyName) {
        pharmacyData.setSelectedPharmacyName('Mail Order');
      }

      rootRef.current?.querySelectorAll('leaf-tab').forEach((tabs) => {
        tabs.addEventListener('click', () => {
          updateFooterInfo();
          requestDropdownUpdate();
        });
      });
      (document.querySelector('#igse-focus-anchor') as HTMLElement)?.focus();
    })();

    return () => {
      rootRef.current?.removeEventListener('returnSearch', returnSearch);
      rootRef.current?.removeEventListener('passEnroll', passEnroll);
      rootRef.current?.removeEventListener('comparePlanView', showComparePlanSection);
      rootRef.current?.removeEventListener('leaf-click', tabsClick);
      document?.removeEventListener('showProviderRemoveModal', openProviderRemoveModal);
      document?.removeEventListener('providerpull', providerinit);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('leaf-dropdown:updated', dropdownUpdate);
    window.addEventListener('leaf-checkgroup-change', toggleCompareSectionPlanCard);
    rootRef.current
      ?.querySelector('leaf-tab[mobile-variant="dropdown"]')
      ?.shadowRoot?.querySelector('leaf-select')
      ?.addEventListener('change', handleTabDropdownChange);

    return () => {
      document.removeEventListener('leaf-dropdown:updated', dropdownUpdate);
      window.removeEventListener('leaf-checkgroup-change', toggleCompareSectionPlanCard);
      rootRef.current
        ?.querySelector('leaf-tab[mobile-variant="dropdown"]')
        ?.shadowRoot?.querySelector('leaf-select')
        ?.removeEventListener('change', handleTabDropdownChange);
    };
  }, [state.planArrayResponse]);

  useEffect(() => {
    updateTabDropdownContent();
  }, [state.maPlanArray, state.pdpPlanArray, state.suppPlanArray]);

  const openOutageModal = async () => {
    if (document.querySelector('leaf-tab[active]')?.getAttribute('label') === 'supplemental') {
      (document?.querySelector(`#outage-modal`) as any)?.toggleModal();
    }
  };

  const removeOutageModal = (fromClose = false) => {
    if (hasOutage === 'TRUE') {
      const activeTab = state.maPlanArray.length > 0 ? 'ma' : 'pdp';
      toggleActiveTabs('supplemental', activeTab);
      if (!fromClose) (document?.querySelector(`#outage-modal`) as any)?.toggleModal();

      const urlObj = new URL(window.location.href);
      if (window.location.hash !== `#${activeTab}`) {
        urlObj.hash = activeTab;
      }
      const path = urlObj.href.replace(urlObj.origin, '');
      navigate(path);
    }
  };

  const renderOutageModal = () => {
    return (
      <leaf-modal
        has-focus-trap="true"
        id="outage-modal"
        size="md"
        dialog
        onClick={() => removeOutageModal(true)}
        aria-label={errorContent.t('show.errorOutageLabel')}
      >
        <h3 slot="heading">{errorContent.t('show.errorOutageHeader')}</h3>
        {HTMLReactParser(errorContent.t('show.errorOutageMessage'))}
        <p>
          <leaf-button size="lg" type="button" variant="secondary" onClick={() => removeOutageModal(false)}>
            {errorContent.t('show.errorOutageButton')}
          </leaf-button>
        </p>
      </leaf-modal>
    );
  };

  const renderTX_NV_StateMessage = () => {
    return (
      (userData.location?.state === 'TX' || userData.location?.state === 'NV') && (
        <p>
          <strong>
            {suppContent.t('footNotes.medSuppInsuredBy')} {suppContent.t('footNotes.cic')}.
          </strong>
        </p>
      )
    );
  };

  const renderMaintenanceBanner = () => {
    return (
      <>
        {(suppContent.t('misc.showMaintenanceBanner') === 'TRUE' && (
          <leaf-alert variant="standalone" type="info" subtle open class="maintenance-alert">
            {suppContent.t('misc.maintenanceMessage')}
          </leaf-alert>
        )) ||
          ''}
      </>
    );
  };

  const renderQuizBannerSupp = () => {
    return (
      <leaf-alert variant="standalone" type="info" subtle open>
        {HTMLReactParser(planResultsContent.t('misc.dualYearEnrollHeaderShoppingQuizHeaderSupp'))}
      </leaf-alert>
    );
  };

  return (
    <div id="plans-view" ref={rootRef}>
      <div className="results">
        <div className="error-drx-down" hidden>
          <leaf-alert variant="standalone" type="error" open>
            <h3 slot="heading">{errorContent.t('show.errorInit')}</h3>
            {errorContent.t('show.tryAgain')}
          </leaf-alert>
        </div>
        <div className="errorInit" hidden>
          <leaf-alert variant="banner" type="error" open>
            <p>
              {errorContent.t('show.errorInit')}&nbsp;{errorContent.t('show.tryAgain')}
            </p>
          </leaf-alert>
        </div>
        <div className="errorZip" hidden>
          <leaf-alert variant="banner" type="error" open>
            <p>{errorContent.t('show.errorGeneral')}</p>
          </leaf-alert>
        </div>
        {hasErrorState.apiDownError && (
          <leaf-alert variant="banner" type="error" class="errorapidown" open>
            <p>{errorContent.t('show.errorWithPhone')}</p>
          </leaf-alert>
        )}
        <div className="errorMedSup" hidden>
          <leaf-alert variant="banner" type="error" open>
            <p>{errorContent.t('show.errorMedSupp')}</p>
          </leaf-alert>
        </div>
        <div className="errorRateQuote" hidden>
          <leaf-alert variant="banner" type="error" open>
            <p>{errorContent.t('show.errorPricing')}</p>
          </leaf-alert>
        </div>
        <div className="supp-form-success" hidden>
          <leaf-alert type="success" variant="banner" open>
            <h3 slot="heading">{suppContent.t('misc.successHeader')}</h3>
            <p>
              {suppPlanLength() > 1 ? suppPlanLength() : ''} {alertSuccessMessage}
            </p>
          </leaf-alert>
        </div>
        <leaf-flex-container class="plans-view-header">
          <h1 className="location leaf-u-typography-headline-default">
            {planResultsContent.t('misc.plansAvailable')} {userData.location?.county}, {userData.location?.state}{' '}
            {userData.location?.zip}
          </h1>
          <leaf-button variant="secondary" size="sm" onClick={updateLocation}>
            {planResultsContent.t('misc.edit')}
            <leaf-icon slot="icon-before" set="system" name="location-on"></leaf-icon>
          </leaf-button>
        </leaf-flex-container>
        {/* dual year plan content */}
        {/* {userData.year?.toString() === '2024' && (
          <p>
            <leaf-button-link
              onClick={setYear.bind(this, 2025)}
              data-custom={`prop13:ac24769^eVar51:${planResultsContent.t(
                'ma.resultsHeader',
              )}^eVar119:CSTD-3124^eVar120:shop-plans-view-next-year-plans`}
            >
              {planResultsContent.t('misc.dualYearReturntoCurrentLink')}
            </leaf-button-link>
          </p>
        )} */}
        {modal()}
        <leaf-tab mobile-variant="dropdown" tab-align="justified">
          <leaf-tab-item
            slot="tab"
            scroll-target
            class="focus-visible"
            aria-selected
            label="ma"
            active
            size="large"
            data-en-content={enOnlyContent.plansView.maTab}
            onClick={setImpressionPlanViewTab}
            tab-align="justified"
            data-custom={`prop13:ac24763^eVar51:${enOnlyContent.plansView.maTab}^eVar119:CSTD-3124^eVar120:shop-plans-product-tab-nav`}
            data-impression={`shop-plans-available^1^^${planResultsContent.t('ma.resultsHeader')}^${Md5.hashStr(
              planResultsContent.t('ma.resultsHeader'),
            )}^${state.maPlanArray.length}`}
          >
            {planResultsContent.t('ma.resultsHeader')} ({state.maPlanArray.length})
          </leaf-tab-item>
          <leaf-tab-item
            slot="tab"
            label="supplemental"
            size="large"
            class="focus-visible"
            data-en-content={enOnlyContent.plansView.suppTab}
            onClick={setImpressionPlanViewTab}
            onFocus={() => mySelectedPlans.setCompare(undefined)}
            tab-align="justified"
            data-impression={`shop-plans-available^2^^${suppContent.t('misc.resultsHeader')}^${Md5.hashStr(
              suppContent.t('misc.resultsHeader'),
            )}^${suppPlanLength()}`}
            data-custom={`prop13:ac24763^eVar51:${enOnlyContent.plansView.suppTab}^eVar119:CSTD-3124^eVar120:shop-plans-product-tab-nav`}
          >
            {suppContent.t('misc.resultsHeader')} ({suppPlanLength()})
          </leaf-tab-item>
          <leaf-tab-item
            slot="tab"
            label="pdp"
            size="large"
            class="focus-visible"
            onClick={setImpressionPlanViewTab}
            tab-align="justified"
            data-en-content={enOnlyContent.plansView.pdpTab}
            data-impression={`shop-plans-available^3^^${planResultsContent.t('pdp.resultsHeader')}^${Md5.hashStr(
              planResultsContent.t('pdp.resultsHeader'),
            )}^${state.pdpPlanArray.length}`}
            data-custom={`prop13:ac24763^eVar51:${enOnlyContent.plansView.pdpTab}^eVar119:CSTD-3124^eVar120:shop-plans-product-tab-nav`}
          >
            {planResultsContent.t('pdp.resultsHeader')} ({state.pdpPlanArray.length})
          </leaf-tab-item>
          <leaf-tab-section slot="tab-section" active={true} label="ma">
            {renderTX_NV_StateMessage()}
            <h2 className="leaf-u-typography-title-default">
              {planResultsContent.t('ma.resultsHeader')} ({state.maPlanArray.length}{' '}
              {planResultsContent.t('misc.availableFor')} {userData.year})
            </h2>
            {/* Code needed for dual plan year */}
            <leaf-alert
              variant="standalone"
              type="info"
              class="aep-year"
              subtle
              open
              // {...(userData.year?.toString() === '2024' && { hidden: true })}
            >
              {/* dual year plan content */}
              {/* <h3 slot="heading">
                {currentYear.toString() !== userData.year?.toString()
                  ? HTMLReactParser(planResultsContent.t('misc.dualYearEnrollHeaderMA'))
                  : HTMLReactParser(planResultsContent.t('misc.dualYearEnrollHeaderCurrentYear'))}
                &nbsp;
                {userData.year?.toString() !== '2025' && (
                  <>
                    <leaf-button-link
                      onClick={setYear.bind(this, 2025)}
                      data-custom="prop13:ac24771^eVar51:{content.ma.resultsHeader}^eVar119:CSTD-3124^eVar120:shop-plans-view-next-year-plans"
                    >
                      {planResultsContent.t('misc.aepCurrentYearLink')}.
                    </leaf-button-link>
                    <br />
                  </>
                )}
              </h3> */}
              <h3 slot="heading">
                {HTMLReactParser(planResultsContent.t('misc.aepMAEnrollHeader'))}
                <br />
                {HTMLReactParser(planResultsContent.t('misc.dualYearEnrollHeaderMA'))}
              </h3>
              <p>
                {HTMLReactParser(planResultsContent.t('misc.dualYearEnrollHeaderTeleMA'))}{' '}
                {HTMLReactParser(planResultsContent.t('misc.dualYearEnrollHeaderShoppingQuizHeader'))}
              </p>
            </leaf-alert>
            <div className="aep-year" hidden>
              <leaf-alert variant="standalone" type="info" class="aep-year" open subtle hidden>
                {HTMLReactParser(planResultsContent.t('misc.dualYearReturntoCurrentHeader'))}
                <p>
                  <leaf-button-link
                    onClick={setYear.bind(this, 2025)}
                    data-custom={`prop13:ac24769^eVar51:${planResultsContent.t(
                      'ma.resultsHeader',
                    )}^eVar119:CSTD-3124^eVar120:shop-plans-view-next-year-plans`}
                  >
                    {planResultsContent.t('misc.dualYearReturntoCurrentLink')}
                  </leaf-button-link>
                </p>
              </leaf-alert>
            </div>
            {state.isMALoading && (
              <leaf-spinner role="alert" aria-live="assertive" size="large">
                <span>{planResultsContent.t('misc.loading')}</span>
              </leaf-spinner>
            )}
            {!hasErrorState.session ? (
              <leaf-spinner hidden role="alert" aria-live="assertive" size="large">
                <span>{planResultsContent.t('misc.loading')}</span>
              </leaf-spinner>
            ) : null}
            {!state.isMALoading && state.maPlanArray.length === 0 && (
              <div className="error-ma-no-results">
                <leaf-alert variant="standalone" open>
                  <h3 slot="heading">{planResultsContent.t('misc.noPlanCoverage')}</h3>
                </leaf-alert>
              </div>
            )}
            {!state.isMALoading && state.maPlanArray.length > 0 && (
              <div className="ma-results">
                <div className="filters-wrapper">
                  <div className="sortby-section">
                    <leaf-dropdown name="ma-sort">
                      <span slot="dropdown-label">{planResultsContent.t('misc.sortBy')}</span>
                      <leaf-dropdown-item value="premium-low">
                        {planResultsContent.t('misc.lowestPremium')}
                      </leaf-dropdown-item>
                      <leaf-dropdown-item value="total-low">
                        {planResultsContent.t('misc.totalCost')}
                      </leaf-dropdown-item>
                      <leaf-dropdown-item value="PlanRating-high">
                        {planResultsContent.t('misc.highestStars')}
                      </leaf-dropdown-item>
                      <leaf-dropdown-item value="PlanName-low" selected>
                        {planResultsContent.t('misc.planName')}
                      </leaf-dropdown-item>
                    </leaf-dropdown>
                  </div>
                  <div className="costfilter-section">
                    <h6>{planResultsContent.t('misc.costSectionHeader')}</h6>
                    <div className="extrahelp-section">{renderExtraHelp()}</div>
                    <div className="pharmacy-section">
                      {renderPharmacyDropdown()}
                      <leaf-button-link onClick={goToPharm}>
                        {planResultsContent.t('misc.pharmacyLink')}
                      </leaf-button-link>
                    </div>
                  </div>
                </div>
                <ul className="leaf-container-4-4-4">
                  {state.maPlanArray.sort(sortMethods[masort]).map((plan) => (
                    <li key={plan.ID}>
                      <PlanCard data={plan} providers={myProviders.data.providers} planType="ma"></PlanCard>
                    </li>
                  ))}
                  <li>
                    <chc-promo variant="simple" color="default" size="small">
                      <leaf-icon slot="icon" set="chc" name="medical-receptionist"></leaf-icon>
                      <h3 slot="heading">{HTMLReactParser(planResultsContent.t('ma.callText'))}</h3>
                      <span slot="content">{HTMLReactParser(planResultsContent.t('ma.callTextHours'))}</span>
                    </chc-promo>
                  </li>
                </ul>
              </div>
            )}
          </leaf-tab-section>
          <leaf-tab-section slot="tab-section" label="supplemental">
            {state.isSuppLoading ? (
              <leaf-spinner role="alert" aria-live="assertive" size="large" mode="indeterminate">
                <span>{planResultsContent.t('misc.loading')}</span>
              </leaf-spinner>
            ) : (
              <>
                {state.isNoApproval && (
                  <div className="no-approval">
                    <h2 className="leaf-u-typography-title-default">
                      {suppContent.t('misc.resultsHeader')}
                      <sup>1</sup>
                    </h2>
                    {renderNoApprovalMessage()}
                  </div>
                )}
                {state.isSellApproval && (
                  <div className="sell-approval">
                    {renderStateMessages()}
                    <h2 className="leaf-u-typography-title-default">
                      {suppContent.t('misc.resultsHeader')}
                      <sup>1</sup>
                    </h2>

                    {renderMaintenanceBanner()}
                    {renderQuizBannerSupp()}
                    <h3>{suppContent.t('notApproved.getQuoteHeader')}</h3>
                    <p>{HTMLReactParser(suppContent.t('notApproved.getQuoteIntro'))}</p>
                    <div className="sell-quote-call">
                      <div>
                        <leaf-button>{suppContent.t('notApproved.getQuoteButton')}</leaf-button>
                        <br />
                        {suppContent.t('notApproved.getQuoteText')}
                        <br />
                        <leaf-button
                          size="lg"
                          type="button"
                          variant="secondary"
                          ismodaltoggle=""
                          data-modal-id="get-quote-modal"
                        >
                          {suppContent.t('notApproved.learnMore')}
                        </leaf-button>
                        <leaf-modal has-focus-trap="true" id="get-quote-modal" size="md" dialog aria-label="Dialog">
                          <div>{HTMLReactParser(suppContent.t('notApproved.getQuoteModal'))}</div>
                          <div slot="cta">
                            <leaf-button>{planResultsContent.t('misc.close')}</leaf-button>
                          </div>
                        </leaf-modal>
                      </div>
                      <div>
                        {suppContent.t('notApproved.orCall')}
                        <br />
                        <chc-phone-number
                          data-custom="prop13:ac23981^eVar119:CSTD-3124^eVar120:shop-plans-click-to-call"
                          number={suppContent.t('notApproved.callGetQuote')}
                          separator="-"
                          parentheses
                          campaign-opt-in
                        ></chc-phone-number>
                        <br />
                        {suppContent.t('notApproved.getQuoteHours')}
                        <br />
                        {suppContent.t('notApproved.tty')}
                      </div>
                    </div>
                  </div>
                )}
                {state.isIgseApproval && (
                  <div className="igse-approval">
                    {state.suppQuestionShown ? (
                      <div className="supp-questions">
                        {renderStateMessages()}
                        <h2 className="leaf-u-typography-title-default">
                          {suppContent.t('misc.resultsHeader')}
                          <sup>1</sup> ({suppPlanLength()} {planResultsContent.t('misc.available')})
                        </h2>
                        {renderMaintenanceBanner()}
                        {renderQuizBannerSupp()}
                        <p>{HTMLReactParser(suppContent.t('form.questionsIntro'))}</p>
                        <div className="form">
                          <form action="/" onSubmit={handleSubmit}>
                            <leaf-label for="first-name">{suppContent.t('form.firstName')}</leaf-label>
                            <leaf-input
                              name="first-name"
                              id="first-name"
                              value={state.suppFormData?.['first-name']}
                              required={true}
                              type="text"
                              pattern=".{2,40}"
                              transform="capitalized"
                            ></leaf-input>
                            <leaf-label for="last-name">{suppContent.t('form.lastName')}</leaf-label>
                            <leaf-input
                              name="last-name"
                              id="last-name"
                              value={state.suppFormData?.['last-name']}
                              required={true}
                              type="text"
                              pattern=".{1,40}"
                              transform="capitalized"
                            ></leaf-input>
                            <leaf-date-picker
                              format="usa"
                              hint="mm/dd/yyyy"
                              label={suppContent.t('form.dob')}
                              name="birthday"
                              value={state.suppFormData?.birthday ? state.suppFormData?.birthday : ''}
                              required
                            ></leaf-date-picker>
                            {state.formError && !state.isRenderingError && (
                              <div id="formError">
                                <leaf-alert variant="inline" type="error" open>
                                  {<p>{HTMLReactParser(state.formError)}</p>}
                                </leaf-alert>
                              </div>
                            )}
                            <leaf-label for="gender">{suppContent.t('form.gender')}</leaf-label>
                            <leaf-select
                              autocomplete="on"
                              error-value-missing={suppContent.t('form.errorGender')}
                              name="gender"
                              id="gender"
                              value={state.suppFormData?.gender}
                              required={true}
                            >
                              <leaf-select-option value="">
                                {suppContent.t('form.genderOptionDefault')}
                              </leaf-select-option>
                              <leaf-select-option
                                selected={state.suppFormData?.gender === 'MALE' ? true : undefined}
                                value="MALE"
                              >
                                {suppContent.t('form.genderOptions.A')}
                              </leaf-select-option>
                              <leaf-select-option
                                selected={state.suppFormData?.gender === 'FEMALE' ? true : undefined}
                                value="FEMALE"
                              >
                                {suppContent.t('form.genderOptions.B')}
                              </leaf-select-option>
                            </leaf-select>

                            <leaf-button
                              data-custom="prop13:ac24511^eVar119:CAKTD-4646^eVar120:medicare-supplement-view-plans-button"
                              type="submit"
                              variant="primary"
                            >
                              {planResultsContent.t('misc.viewPlans')}
                            </leaf-button>
                          </form>
                        </div>
                        <chc-promo variant="simple" color="default" size="default">
                          <leaf-icon
                            slot="icon"
                            fill="currentColor"
                            name="medical-receptionist"
                            set="chc"
                            class="icon-border-medium-primary"
                            aria-label="medical-receptionist"
                            role="img"
                          ></leaf-icon>
                          <h3 slot="heading">{suppContent.t('misc.callForQuote')}</h3>
                          <span slot="content">
                            <chc-phone-number
                              data-custom="prop13:ac23981^eVar119:CSTD-3124^eVar120:shop-plans-click-to-call"
                              number={suppContent.t('misc.infoCallNumber')}
                              separator="-"
                              parentheses
                              campaign-opt-in
                            ></chc-phone-number>
                            &nbsp;{suppContent.t('misc.callQuoteDescription')}
                          </span>
                        </chc-promo>
                      </div>
                    ) : (
                      <div className="supp-data" tabIndex={-1}>
                        {renderStateMessages()}
                        <h2 className="leaf-u-typography-title-default">
                          {suppContent.t('misc.resultsHeader')}
                          <sup>1</sup> ({suppPlanLength()} {planResultsContent.t('misc.available')})
                        </h2>
                        {renderMaintenanceBanner()}
                        {renderQuizBannerSupp()}
                        <p className="supp-results-intro">
                          {resultsIntro[0]}
                          <leaf-button-link onClick={viewSuppQuestions}>
                            {suppContent.t('misc.resultsIntroLink')}
                          </leaf-button-link>
                          {resultsIntro[1]}
                        </p>
                        {state.isSuppPlansFetching || state.showSuppEnrollSpinner ? (
                          <leaf-spinner role="alert" aria-live="assertive" size="large" mode="indeterminate">
                            <span>{planResultsContent.t('misc.loading')}</span>
                          </leaf-spinner>
                        ) : (
                          <>
                            {state.suppPlanArray.length === 0 && (
                              <leaf-alert variant="standalone" open>
                                <h3 slot="heading">{errorContent.t('show.errorNoCover')}</h3>
                              </leaf-alert>
                            )}
                            {state.suppPlanArray.length > 0 && (
                              <ul className="leaf-container-4-4-4">
                                {state.suppPlanArray
                                  .sort((a: any, b: any) => {
                                    if (a.order < b.order) {
                                      return -1;
                                    }
                                    if (a.order > b.order) {
                                      return 1;
                                    }
                                    return 0;
                                  })
                                  .map((plan: any) => (
                                    <li key={plan.productID}>
                                      <PlanCard
                                        data={plan}
                                        planType="supp"
                                        isEligibleBefore={state.isEligibleBefore}
                                        handleSuppEnrollSpinner={handleSuppEnrollSpinner}
                                      ></PlanCard>
                                    </li>
                                  ))}
                                <li>
                                  <chc-promo variant="simple" color="default" size="small">
                                    <leaf-icon
                                      slot="icon"
                                      name="medical-receptionist"
                                      set="chc"
                                      class="icon-border-medium-primary"
                                      aria-label="medical-receptionist"
                                      role="img"
                                    ></leaf-icon>
                                    <h3 slot="heading">{suppContent.t('misc.callForQuote')}</h3>
                                    <span slot="content">
                                      <chc-phone-number
                                        data-custom="prop13:ac23981^eVar119:CSTD-3124^eVar120:shop-plans-click-to-call"
                                        number={suppContent.t('misc.callNumber')}
                                        separator="-"
                                        parentheses
                                        campaign-opt-in
                                      ></chc-phone-number>
                                      &nbsp;{suppContent.t('misc.callQuoteDescription')}
                                    </span>
                                  </chc-promo>
                                </li>
                              </ul>
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                )}
                <DisclaimerFootNotes
                  supPlanArrayresponse={state.supPlanArrayresponse}
                  location={userData.location}
                  charter={state.charter}
                  isEligibleBefore={state.isEligibleBefore}
                  displayAgeEligibleFootnote={state.displayAgeEligibleFootnote}
                />
              </>
            )}
          </leaf-tab-section>
          <leaf-tab-section slot="tab-section" label="pdp">
            {renderTX_NV_StateMessage()}
            <h2 className="leaf-u-typography-title-default">
              {planResultsContent.t('pdp.resultsHeader')} ({state.pdpPlanArray.length}{' '}
              {planResultsContent.t('misc.availableFor')} {userData.year})
            </h2>
            <leaf-alert
              variant="standalone"
              type="info"
              class="aep-year"
              subtle
              open
              // {...(userData.year?.toString() === '2024' && { hidden: true })}
            >
              {/* dual year plan content */}
              {/* <h3 slot="heading">
                {currentYear.toString() !== userData.year?.toString()
                  ? HTMLReactParser(planResultsContent.t('misc.dualYearEnrollHeaderPDP'))
                  : HTMLReactParser(planResultsContent.t('misc.dualYearEnrollHeaderCurrentYear'))}
                &nbsp;
                {userData.year?.toString() !== '2025' && (
                  <>
                    <leaf-button-link
                      onClick={setYear.bind(this, 2025)}
                      data-custom="prop13:ac24771^eVar51:{content.ma.resultsHeader}^eVar119:CSTD-3124^eVar120:shop-plans-view-next-year-plans"
                    >
                      {planResultsContent.t('misc.aepCurrentYearLink')}.
                    </leaf-button-link>
                    <br />
                  </>
                )}
              </h3>
              {currentYear.toString() !== userData.year?.toString() && (
                <p>
                  {HTMLReactParser(planResultsContent.t('misc.dualYearEnrollContent'))}
                  <leaf-button-link
                    onClick={setYear.bind(this, 2024)}
                    data-custom="prop13:ac24771^eVar51:{content.pdp.resultsHeader}^eVar119:CSTD-3124^eVar120:shop-plans-view-current-year-plans"
                  >
                    {planResultsContent.t('misc.dualYearEnrollLink')}
                  </leaf-button-link>
                  <p>{HTMLReactParser(planResultsContent.t('misc.dualYearEnrollHeaderTelePDP'))}</p>
                </p>
              )} */}
              <h3 slot="heading">{HTMLReactParser(planResultsContent.t('misc.dualYearEnrollHeaderPDP'))}</h3>
              <p>{HTMLReactParser(planResultsContent.t('misc.dualYearEnrollHeaderTelePDP'))}</p>
            </leaf-alert>
            <leaf-alert variant="standalone" type="info" subtle open class="pdp-alert">
              <h3 slot="heading">
                {planResultsContent.t('pdp.noMedCoverage')}
                {state.maPlanArray.length !== 0 && (
                  <>
                    &nbsp;
                    <leaf-button-link onClick={switchTab}>
                      {planResultsContent.t('pdp.shopMedCoverage')}
                    </leaf-button-link>
                    {userData.year.toString() === '2024' &&
                      HTMLReactParser(planResultsContent.t('misc.infoBannerCurrentYearAssurance'))}
                  </>
                )}
              </h3>
            </leaf-alert>
            <div className="aep-year" hidden>
              <leaf-alert variant="standalone" type="info" open subtle>
                {HTMLReactParser(planResultsContent.t('misc.dualYearReturntoCurrentHeader'))}
                <p>
                  <leaf-button-link
                    onClick={setYear.bind(this, 2025)}
                    data-custom={`prop13:ac24769^eVar51:${planResultsContent.t(
                      'pdp.resultsHeader',
                    )}^eVar119:CSTD-3124^eVar120:shop-plans-view-next-year-plans`}
                  >
                    {HTMLReactParser(planResultsContent.t('misc.dualYearReturntoCurrentLink'))}
                  </leaf-button-link>
                </p>
              </leaf-alert>
            </div>
            {state.isPDPLoading && (
              <leaf-spinner role="alert" aria-live="assertive" size="large" mode="indeterminate">
                <span>{planResultsContent.t('misc.loading')}</span>
              </leaf-spinner>
            )}
            {!state.isPDPLoading && state.pdpPlanArray.length === 0 && (
              <div className="error-pdp-no-results">
                <leaf-alert variant="standalone" open>
                  <h3 slot="heading">{planResultsContent.t('misc.noPlanCoverage')}</h3>
                </leaf-alert>
              </div>
            )}
            {!state.isPDPLoading && state.pdpPlanArray.length > 0 && (
              <div className="pdp-results">
                <div className="filters-wrapper">
                  <div className="sortby-section">
                    <leaf-dropdown name="pdp-sort">
                      <span slot="dropdown-label">{planResultsContent.t('misc.sortBy')}</span>
                      <leaf-dropdown-item value="premium-low" selected>
                        {planResultsContent.t('misc.lowestPremium')}
                      </leaf-dropdown-item>
                      <leaf-dropdown-item value="total-low">
                        {planResultsContent.t('misc.totalCost')}
                      </leaf-dropdown-item>
                      <leaf-dropdown-item value="PlanRating-high">
                        {planResultsContent.t('misc.highestStars')}
                      </leaf-dropdown-item>
                      <leaf-dropdown-item value="PlanName-low">
                        {planResultsContent.t('misc.planName')}
                      </leaf-dropdown-item>
                    </leaf-dropdown>
                  </div>
                  <div className="costfilter-section">
                    <h6>{planResultsContent.t('misc.costSectionHeader')}</h6>
                    <div className="extrahelp-section">{renderExtraHelp()}</div>
                    <div className="pharmacy-section">
                      {renderPharmacyDropdown()}
                      <leaf-button-link onClick={() => goToPharm()}>
                        {planResultsContent.t('misc.pharmacyLink')}
                      </leaf-button-link>
                    </div>
                  </div>
                </div>

                <ul className="leaf-container-4-4-4">
                  {state.pdpPlanArray.sort(sortMethods[pdpsort]).map((plan) => (
                    <li key={plan.ID}>
                      <PlanCard data={plan} planType="pdp" maPlansLength={state.maPlanArray.length} />
                    </li>
                  ))}
                  <li>
                    <chc-promo variant="simple" color="default" size="small">
                      <leaf-icon
                        slot="icon"
                        name="medical-receptionist"
                        set="chc"
                        class="icon-border-medium-primary"
                        aria-label="medical-receptionist"
                        role="img"
                      ></leaf-icon>
                      <h3 slot="heading">{HTMLReactParser(planResultsContent.t('pdp.callText'))}</h3>
                      <span slot="content">{HTMLReactParser(planResultsContent.t('pdp.callTextHours'))}</span>
                    </chc-promo>
                  </li>
                </ul>
                <div className="row iw_row">
                  <div className="columns iw_columns large-7">
                    <p className="pdp-footnote-copy">{HTMLReactParser(planResultsContent.t('pdp.footnoteContent'))}</p>
                  </div>
                </div>
              </div>
            )}
          </leaf-tab-section>
        </leaf-tab>
        {hasOutage === 'TRUE' && renderOutageModal()}
        {renderProviderRemoveModal()}
        <Suspense>
          <ShoppingWizard />
        </Suspense>
      </div>
      <CompareBox />
    </div>
  );
};

export default PlansView;
