import React, { FC, useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { ISearchParams, getParams } from '../../url.js';
import { sanitize } from '../../utils/sanitize.js';
import { customTagEvent } from '../../utils/analytics/utils.js';
import { isMobile } from '@cigna/dcws_leaf-web-components/utilities/is-mobile.js';
import './compare-plans.scss';

// //hooks
import useUserData from '../../hooks/use-user-data.js';
import usePharmacyData from '../../hooks/use-pharmacy-data.js';
import useUserPlanSelector from '../../hooks/use-user-plan-selector.js';
import useDrugPlanSelector from '../../hooks/use-drug-selector.js';
import useErrorStates from '../../hooks/use-error-states.js';

import { IUserSession } from '../../context/user-context.js';

import { planDetailAPI } from '../../utils/api/plans-view.js';
import { enroDRX } from '../../utils/apiHelper/igse-session.js';
import { replacePharma } from '../../utils/api/pharmacy.js';
import { IMedicarePlan, IMedicarePlanCopays } from '../../utils/api/interfaces.js';
import { environment } from '../../app.js';

// // Code needed for dual plan year - donot delete
import expiringPlans from '../../../public/locales/expiring-plans.json';
import enOnlyContent from '../../../public/locales/en-only-content.json';
import enrollWarningPlans from '../../../public/locales/enroll-warning-plans.json';
import HTMLReactParser from 'html-react-parser';
import useAnalyticsData from '../../hooks/use-analytics-data.js';
import { IAnalytics } from '../../context/analytics-context.js';
import EnrollWarningModal from '../../components/enroll-warning-modal/enroll-warning-modal.js';

interface ComparePlansProps {}

interface MyState {
  selectedPlans: Array<IMedicarePlan>;
  isExpired: boolean;
  planType: string;
  renderMobileView: boolean;
  calloutInfoHeading: string;
  state: string;
  dataImpressionArr: [];
  currentYear: number | undefined;
  enrollWarningContent?: string;
  mobileHeightSet: boolean;
}

interface ITierInfo {
  days: number;
  benefit: boolean;
  prefer: boolean;
  mail: boolean;
}

interface ITierData {
  row1Label: string;
  row1Info: string;
  row2Label: string;
  row2Info: string;
}

interface IWarningObj {
  block: string[];
  medicaid: string[];
  diabetes: string[];
}

const ComparePlans: FC<ComparePlansProps> = () => {
  const content = useTranslation('plan-results-content');
  const compareContent = useTranslation('compare-plan-content');
  const planDetailsContent = useTranslation('plan-details-content');
  const planResultsContent = useTranslation('plan-results-content');
  const routeLocation = useLocation();
  const navigate = useNavigate();
  const userData = useUserData();
  const analyticsData = useAnalyticsData();
  const pharmacyData = usePharmacyData();
  const mySelectedPlans = useUserPlanSelector();
  const mydrugselector = useDrugPlanSelector();
  const hasErrorState = useErrorStates();
  const [state, setState] = useState<MyState>({
    selectedPlans: [],
    isExpired: false,
    planType: '',
    renderMobileView: false,
    calloutInfoHeading: '',
    state: '',
    dataImpressionArr: [],
    currentYear: userData.year,
    mobileHeightSet: false,
  });
  const rootRef = useRef<HTMLDivElement>(null);
  const currentPlanYear = new Date().getFullYear();
  const hasEnroll =
    (currentPlanYear.toString() === userData.year?.toString() &&
      (content.t('misc.currentYearShowEnrollModal') === 'true' ||
        content.t('misc.currentYearShowEnroll') === 'true')) ||
    (currentPlanYear.toString() !== userData.year?.toString() &&
      (content.t('misc.nextYearShowEnrollModal') === 'true' || content.t('misc.nextYearShowEnroll') === 'true')) ||
    environment.showAllEnroll;

  const showExtraHelp = () => {
    const urlObj = new URL(window.location.href);
    urlObj.pathname = '/extra-help';

    const path = urlObj.href.replace(urlObj.origin, '');
    navigate(path, { state: { pageNumber: 1, from: 'comparePlanView' } });
  };

  const supNumber = (number: Number) => {
    const splitNum = number.toFixed(2).toString().split('.');
    return (
      <>
        ${splitNum[0]}
        <sup>{splitNum[1]}</sup>
      </>
    );
  };

  const renderEst = (data: IMedicarePlan) => {
    let totalEst: string;
    if (
      (mydrugselector.dosageList.length > 0 &&
        pharmacyData.selectedPharmacyName === 'Mail Order' &&
        data.PlanType !== 4) ||
      (mydrugselector.dosageList.length > 0 && pharmacyData.selectedPharmacyName !== 'Mail Order') ||
      data.PlanType === 4
    ) {
      const pharmaIndex = getPharmacyIndex(data);
      if (pharmaIndex > -1) {
        totalEst = numberWithCommas(getMonthlyDrugCosts(data, pharmaIndex, false));
      } else {
        totalEst = numberWithCommas(0);
      }
    } else {
      totalEst = numberWithCommas(0);
    }
    return totalEst;
  };

  const getMonthlyDrugCosts = (data: IMedicarePlan, pharmaIndex: number, withPremium = true) => {
    let costPreEst: number = 0;
    const totalMonth = getTotalMonth();
    if (pharmaIndex > -1) {
      for (let startMonth = 0; startMonth < totalMonth; startMonth++) {
        const drugMC = data.PharmacyCosts[pharmaIndex].MonthlyCosts[startMonth];
        const array1 = drugMC.CostDetail?.map((fullcost) => fullcost.MemberCost);
        const reducer = (accumulator: number, currentValue: number) => accumulator + currentValue;
        costPreEst += array1?.reduce(reducer);
        if (withPremium) {
          costPreEst += data.MedicalPremium + data.DrugPremium;
        }
      }
    }
    return costPreEst;
  };

  const getPharmacyIndex = (data: IMedicarePlan) => {
    const selectedPharmacy = pharmacyData.pharmacies?.find(
      (pharmacy: any) => pharmacy.Name === pharmacyData.selectedPharmacyName,
    );
    const pharmacyId = selectedPharmacy?.PharmacyID ?? null;

    let pharmaIndex: number;
    if (pharmacyId === null) {
      pharmaIndex = data.PharmacyCosts.findIndex((PharmacyCosts) => {
        const compare1 = PharmacyCosts.PharmacyType === 2;
        const compare2 = Object.prototype.hasOwnProperty.call(PharmacyCosts, 'MonthlyCosts');
        return compare1 && compare2;
      });
    } else {
      pharmaIndex = data.PharmacyCosts.findIndex((PharmacyCosts) => {
        const compare1 = pharmacyId === PharmacyCosts.PharmacyID;
        const compare2 = Object.prototype.hasOwnProperty.call(PharmacyCosts, 'MonthlyCosts');
        return compare1 && compare2;
      });
    }
    return pharmaIndex;
  };

  const getTotalMonth = () => {
    let totalMonth = 12;
    let firstMonth = 0;
    const today = new Date();
    if (userData.year === state.currentYear) {
      firstMonth = today.getMonth() + 1;
      totalMonth = 12 - firstMonth;
    }
    return totalMonth;
  };

  const renderTotalCost = (data: IMedicarePlan) => {
    const totalMonth = getTotalMonth();
    let costPreEst: number = 0;
    if (mydrugselector.dosageList.length > 0 && data.PlanType !== 4) {
      // TODO: Test when pharmacy search developed
      const pharmacyIndex = getPharmacyIndex(data);
      if (pharmacyIndex > -1) {
        costPreEst += getMonthlyDrugCosts(data, pharmacyIndex);
      }
    } else {
      costPreEst = data.DrugPremium * totalMonth + data.MedicalPremium * totalMonth;
    }

    const totalCost = numberWithCommas(costPreEst);
    return totalCost;
  };

  const convertPercentage = (totalValue: number) => {
    return 100 * totalValue;
  };

  const numberWithCommas = (rawNum: number) => {
    if (rawNum !== undefined) {
      return rawNum.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }
    return '';
  };

  const individualTierDisplay = (plan: IMedicarePlan, index: number) => {
    const returnHTML: Array<JSX.Element> = [];
    const costTier = (copaydetails: IMedicarePlanCopays, tierInfo: ITierInfo) => {
      if (
        copaydetails.DaysOfSupply === tierInfo.days &&
        copaydetails.DefaultBenefit === tierInfo.benefit &&
        copaydetails.IsPreferredPharmacy === tierInfo.prefer &&
        copaydetails.IsMailOrder === tierInfo.mail
      ) {
        if (copaydetails.Cost !== undefined) {
          if (copaydetails.Cost % 1 === 0) {
            return numberWithCommas(copaydetails.Cost);
          } else if (copaydetails.Cost % 1 !== 0) {
            return `${convertPercentage(copaydetails.Cost)}%`;
          }
        } else {
          return compareContent.t('pharmacyInfo.naText');
        }
      }
      return;
    };

    const tierInfoArray: ITierInfo[] = [
      { days: 90, benefit: true, prefer: true, mail: true },
      { days: 30, benefit: true, prefer: true, mail: false },
      { days: 30, benefit: true, prefer: false, mail: false },
      { days: 30, benefit: false, prefer: false, mail: true },
    ];

    if (plan.FormularyTiers.length > 0) {
      plan.FormularyTiers?.forEach((tierdetails, subindex: number) => {
        const tierCopay = tierdetails.CopayPrices;
        returnHTML.push(<span key={`${subindex}-tier-div`}>{tierdetails.TierDescription}:&nbsp;</span>);
        let tierData = false;
        tierCopay?.forEach((copaydetails: IMedicarePlanCopays) => {
          const costTierHTML = costTier(copaydetails, tierInfoArray[index]);
          if (costTierHTML !== undefined) {
            tierData = true;
          }
          returnHTML.push(costTierHTML);
        });
        if (!tierData) {
          returnHTML.push(compareContent.t('pharmacyInfo.naText'));
        }
        returnHTML.push(<br key={`${subindex}-tier-br`} />);
      });
    } else {
      returnHTML.push(compareContent.t('pharmacyInfo.naText'));
    }
    return returnHTML;
  };

  const displayIndividualTier = (index: number) => {
    const returnHTML: Array<JSX.Element> = [];
    state.selectedPlans.forEach((plan: IMedicarePlan, subIndex: number) => {
      returnHTML.push(
        <td key={`${subIndex}-tier`} part="tbody-td">
          {individualTierDisplay(plan, index)}
        </td>,
      );
    });
    return returnHTML;
  };

  const renderTierDisplay = () => {
    const returnHTML: Array<JSX.Element> = [];
    const deliveryLabels = [
      {
        label: compareContent.t('pharmacyInfo.preferredDeliveryLabel'),
        supply: compareContent.t('pharmacyInfo.day90Supply'),
      },
      {
        label: compareContent.t('pharmacyInfo.preferredNetworkLabel'),
        supply: compareContent.t('pharmacyInfo.day30Supply'),
      },
      {
        label: compareContent.t('pharmacyInfo.preferredStandardLabel'),
        supply: compareContent.t('pharmacyInfo.day30Supply'),
      },
    ];

    deliveryLabels.forEach((deliveryLabel: { label: string; supply: string }, index: number) => {
      returnHTML.push(
        <tr key={`${index}-delivery`}>
          <th scope="row" part="th" className="leaf-u-background-gray">
            {deliveryLabel.label}
            <br />
            {deliveryLabel.supply}
          </th>
          {displayIndividualTier(index)}
        </tr>,
      );
    });
    return returnHTML;
  };

  const individualPlanDocument = (planName: string) => {
    const planDocumentsHtml: Array<JSX.Element> = [];
    state.selectedPlans.forEach(function (planData: IMedicarePlan, index: number) {
      const sectionDetails = planData.Documents?.find((el) => el.Name === planName);
      if (sectionDetails !== undefined) {
        planDocumentsHtml.push(
          <td key={`${index}-doc`} part="tbody-td">
            <a
              className="external-link leaf-u-link--external"
              href={sectionDetails?.Url}
              data-custom="prop13:ac23999^eVar120:compare-document-links"
              data-impression={`compare-plans-list^${index + 1}^${planData.PlanName}^${
                planData.PlanType === 1
                  ? planResultsContent.t('pdp.resultsHeader')
                  : planResultsContent.t('ma.resultsHeader')
              }^${planData.ContractID + '-' + planData.PlanID + '-' + planData.SegmentID}^${(
                planData.MedicalPremium + planData.DrugPremium
              )
                .toFixed(2)
                .toString()}`}
              target="_blank"
            >
              {sectionDetails?.LinkName}
            </a>
          </td>,
        );
      } else {
        planDocumentsHtml.push(<td key={`${index}-doc-empty`} part="tbody-td"></td>);
      }
    });

    return planDocumentsHtml;
  };

  const renderPlanDocuments = () => {
    const returnHTML: Array<JSX.Element> = [];
    const documentNames: string[] = [];
    state.selectedPlans.forEach((plan: IMedicarePlan) => {
      plan.Documents.map((doc) => {
        if (doc.Types !== undefined) {
          if (documentNames.indexOf(doc.Name) === -1) {
            documentNames.push(doc.Name);
          }
        }
      });
    });
    documentNames.forEach((documentName: string, index: number) => {
      returnHTML.push(
        <tr key={`${index}-document`}>
          <th scope="row" part="th" className="leaf-u-background-gray">
            {documentName}
          </th>
          {individualPlanDocument(documentName)}
        </tr>,
      );
    });
    return returnHTML;
  };

  const individualMedicalCoverage = (planDescription: string) => {
    const returnHTML: Array<JSX.Element> = [];
    state.selectedPlans.forEach((plan: IMedicarePlan, index: number) => {
      if (plan.PlanType !== 1) {
        const benefitValue = plan.ExtendedPlanDetails.find((sections) => sections)?.SectionDetails?.find(
          (medicalBenefit) => medicalBenefit.Description === planDescription,
        );
        if (benefitValue !== undefined) {
          returnHTML.push(
            <td key={`${index}-med-co`} part="tbody-td">
              {HTMLReactParser(benefitValue?.Value)}
            </td>,
          );
        } else {
          returnHTML.push(<td key={`${index}-med-co-empty`} part="tbody-td"></td>);
        }
      } else {
        returnHTML.push(<td key={`${index}-med-co-empty-other`} part="tbody-td"></td>);
      }
    });
    return returnHTML;
  };

  const individualPharmacyBenefit = (planDescription: string) => {
    const returnHTML: Array<JSX.Element> = [];
    state.selectedPlans.forEach((plan, index: number) => {
      if (plan.PlanType === 1 || plan.PlanType === 2) {
        const extendedPlanDetails =
          plan.PlanType === 2 ? plan.ExtendedPlanDetails[1] : plan.ExtendedPlanDetails.find((sections) => sections);
        const benefitValue = extendedPlanDetails?.SectionDetails?.find(
          (pharmacyBenefit) => pharmacyBenefit.Description === planDescription,
        );
        if (benefitValue !== undefined) {
          if (benefitValue.LinkName === '') {
            returnHTML.push(
              <td key={`${index}-ind-pharm`} part="tbody-td">
                {HTMLReactParser(benefitValue.Value)}
              </td>,
            );
          } else {
            returnHTML.push(
              <td key={`${index}-ind-pharm`} part="tbody-td">
                <a href={benefitValue.Value} target="_blank">
                  {benefitValue.LinkName}
                </a>
              </td>,
            );
          }
        } else {
          returnHTML.push(<td key={`${index}-ind-pharm`} part="tbody-td"></td>);
        }
      } else {
        returnHTML.push(<td key={`${index}-ind-pharm`} part="tbody-td"></td>);
      }
    });
    return returnHTML;
  };

  const renderBenefits = () => {
    const selectedPlansType = state.selectedPlans.map((plan) => plan.PlanType);
    const returnHTML: Array<JSX.Element> = [];
    if (selectedPlansType.includes(1)) {
      const pharmacyDescriptions: string[] = [];
      let gapCoverageBenefit = false;
      state.selectedPlans.forEach((plan) => {
        const sectionDetails = plan.ExtendedPlanDetails?.find((sections) => sections)?.SectionDetails;
        sectionDetails?.map((details, index: number) => {
          if (
            details.Description !== 'Gap Coverage' &&
            details.Description !== 'Prescription Deductible' &&
            pharmacyDescriptions.indexOf(details.Description) === -1
          ) {
            if (pharmacyDescriptions.length > index + 1) {
              pharmacyDescriptions.splice(index, 0, details.Description);
            } else {
              pharmacyDescriptions.push(details.Description);
            }
          } else if (details.Description === 'Gap Coverage') {
            gapCoverageBenefit = true;
          }
        });
      });
      if (gapCoverageBenefit) {
        const pharmDrugTierIndex = pharmacyDescriptions.indexOf('Pharmacy Deductible Drug Tier Exclusions');
        const initialCoverageIndex = pharmacyDescriptions.indexOf('Initial Coverage Limit');
        if (pharmDrugTierIndex > -1) {
          pharmacyDescriptions.splice(pharmDrugTierIndex + 1, 0, 'Gap Coverage');
        } else if (initialCoverageIndex > -1) {
          pharmacyDescriptions.splice(initialCoverageIndex, 0, 'Gap Coverage');
        } else {
          pharmacyDescriptions.push('Gap Coverage');
        }
      }
      pharmacyDescriptions.forEach((description: string, index: number) => {
        if (description !== 'Pharmacy Deductible Drug Tier Exclusions') {
          returnHTML.push(
            <tr key={`${index}-pharm`}>
              <th scope="row" part="th" className="leaf-u-background-gray">
                {description}
              </th>
              {individualPharmacyBenefit(description)}
            </tr>,
          );
        } else {
          const deductibleData = [];
          if (userData.subsidyLevel && userData.subsidyLevel !== '4') {
            const deductibleAmt =
              userData.subsidyLevel === '1' || userData.subsidyLevel === '2' || userData.subsidyLevel === '5'
                ? numberWithCommas(0)
                : numberWithCommas(99);
            state.selectedPlans.map((_plan, index: number) => {
              deductibleData.push(
                <td key={`${index}-deductible`} part="tbody-td">
                  {deductibleAmt}
                </td>,
              );
            });
          } else {
            deductibleData.push(individualPharmacyBenefit(description));
          }
          const deductibleHTML = (
            <tr key={`${index}-annual`}>
              <th key={index} scope="row" className="leaf-u-background-gray">
                {compareContent.t('misc.annualDeductibleLabel')}
              </th>
              {deductibleData}
            </tr>
          );
          returnHTML.push(deductibleHTML);
        }
      });
    } else if (selectedPlansType.includes(2) || selectedPlansType.includes(4)) {
      const medicalBenefitDescriptions: string[] = [];
      state.selectedPlans.forEach((plan) => {
        const sectionDetails = plan.ExtendedPlanDetails?.find((sections) => sections)?.SectionDetails;
        sectionDetails?.map((details, index: number) => {
          if (medicalBenefitDescriptions.indexOf(details.Description) === -1) {
            if (medicalBenefitDescriptions.length > index + 1) {
              medicalBenefitDescriptions.splice(index, 0, details.Description);
            } else {
              medicalBenefitDescriptions.push(details.Description);
            }
          }
        });
      });
      medicalBenefitDescriptions.forEach((description, index: number) => {
        returnHTML.push(
          <tr key={`${index}-medical`}>
            <th scope="row" part="th" className="leaf-u-background-gray">
              {description}
            </th>
            {individualMedicalCoverage(description)}
          </tr>,
        );
      });

      if (selectedPlansType.includes(2)) {
        const pharmacyDescriptions: string[] = [];
        state.selectedPlans.forEach((plan) => {
          if (plan.PlanType === 2) {
            const sectionDetails = plan.ExtendedPlanDetails[1].SectionDetails;
            sectionDetails?.map((details, index: number) => {
              if (pharmacyDescriptions.indexOf(details.Description) === -1) {
                if (pharmacyDescriptions.length > index + 1) {
                  pharmacyDescriptions.splice(index, 0, details.Description);
                } else {
                  pharmacyDescriptions.push(details.Description);
                }
              }
            });
          }
        });
        pharmacyDescriptions.forEach((description, index: number) => {
          returnHTML.push(
            <tr key={`${index}-pharm-benefit`}>
              <th scope="row" part="th" className="leaf-u-background-gray">
                {description}
              </th>
              {individualPharmacyBenefit(description)}
            </tr>,
          );
        });
      }
    }
    return returnHTML;
  };

  const updatedFixedMenu = () => {
    if (!isMobile()) {
      const planDetailsSection = document
        ?.querySelector('.compare-table')
        ?.shadowRoot?.querySelector('.plan-name-details') as HTMLElement;
      if (planDetailsSection !== null) {
        const compareDetailsSection = document?.querySelector('.compare-details-section') as HTMLElement;
        const compareDetailsHeight = compareDetailsSection?.offsetHeight;
        const winYOffset = window.pageYOffset;
        const stickyBar = document?.querySelector('.sticky-bar');
        if (
          winYOffset > compareDetailsSection?.offsetTop + planDetailsSection?.offsetHeight &&
          winYOffset < compareDetailsHeight
        ) {
          addBackListener('.plan-name', '.sticky-bar');
          addBackListener('.enroll-btn', '.sticky-bar');
          stickyBar?.classList.add('show');
          stickyBarWidth(true);
        } else {
          stickyBar?.classList.remove('show');
          stickyBarWidth(false);
        }
      }
    }
  };

  const assignStickyBarTdWidth = () => {
    const stickyBar = document?.querySelector('.sticky-bar');
    const compareDetailsTable = document?.querySelector('.compare-table');
    const stickyBarTd = stickyBar?.querySelector('chc-table')?.shadowRoot?.querySelector('thead td');
    if (stickyBarTd !== null) {
      const tableColgroup = stickyBar?.querySelector('chc-table')?.shadowRoot?.querySelector('colgroup');
      if (tableColgroup) {
        tableColgroup.remove();
      }
      compareDetailsTable?.shadowRoot?.querySelectorAll('thead th').forEach((cell, ind) => {
        if (ind != 0 && stickyBar?.querySelector('chc-table')?.shadowRoot?.querySelectorAll('thead th')[ind - 1]) {
          (
            stickyBar?.querySelector('chc-table')?.shadowRoot?.querySelectorAll('thead th')[ind - 1] as HTMLElement
          ).style.width = `${cell?.getBoundingClientRect().width}px`;
        }
      });
      const compareTableThWidth = compareDetailsTable?.shadowRoot
        ?.querySelectorAll('thead th')[0]
        .getBoundingClientRect().width;
      (stickyBarTd as HTMLElement).style.width = `${compareTableThWidth}px`;
    } else {
      setTimeout(() => {
        assignStickyBarTdWidth();
      }, 100);
    }
  };

  const stickyBarWidth = (setWidth: boolean) => {
    const stickyBar = document?.querySelector('.sticky-bar');
    if (stickyBar) {
      (
        stickyBar?.querySelector('chc-table')?.shadowRoot?.querySelector('.outer') as HTMLElement
      ).style.borderBottomRightRadius = '0px';
      (
        stickyBar?.querySelector('chc-table')?.shadowRoot?.querySelector('.outer') as HTMLElement
      ).style.borderBottomLeftRadius = '0px';
      if (setWidth) {
        assignStickyBarTdWidth();
        const compareDetailsTable = document?.querySelector('.compare-table');
        const left = compareDetailsTable?.getBoundingClientRect().left;
        const width = compareDetailsTable?.getBoundingClientRect().width;
        (stickyBar as HTMLElement).style.left = `${left}px`;
        (stickyBar as HTMLElement).style.width = `${width}px`;
      } else {
        (stickyBar as HTMLElement).style.left = '0px';
        (stickyBar as HTMLElement).style.width = '100%';
      }
    }
  };

  const getMobileViewTbodyContent = (tableRow: string[], index: number) => {
    const returnHTML: Array<JSX.Element> = [];
    tableRow.forEach((tableCell, rowIndex: number) => {
      if (index === 0 || rowIndex === 0) {
        returnHTML.push(
          <th
            key={rowIndex}
            className={rowIndex === 0 ? 'col-fixed' : ''}
            scope={rowIndex !== 0 && rowIndex !== 0 ? 'col' : 'row'}
          >
            <div className={rowIndex !== 0 ? 'content-wrapper' : ''}>{HTMLReactParser(tableCell)}</div>
          </th>,
        );
      } else {
        returnHTML.push(
          <td key={`${rowIndex}-table-mobile`} className={rowIndex === 0 ? 'col-fixed' : ''}>
            <div className={rowIndex !== 0 ? 'body-content-wrapper' : ''}>{HTMLReactParser(tableCell)}</div>
          </td>,
        );
      }
    });
    return returnHTML;
  };

  const renderMobileViewCompareTable = () => {
    if (state.renderMobileView) {
      const returnHTML: Array<JSX.Element> = [];
      const compareTable = document?.querySelector('.compare-details-section table');
      const compareTrs = compareTable?.querySelectorAll('tr:not(.compare-plan-table-header)');
      const tableContentArr: Array<string[]> = [];
      compareTrs?.forEach((row) => {
        row.querySelectorAll('td,th').forEach((tableCell, index) => {
          if (!tableContentArr[index]) {
            tableContentArr[index] = [];
          }
          const cloneElement = tableCell.cloneNode(true) as HTMLElement;
          tableContentArr[index].push(cloneElement.innerHTML);
        });
      });
      const mobileHTML: Array<JSX.Element> = [];
      tableContentArr.map((tableRow, index: number) => {
        mobileHTML.push(<tr key={`${index}-mobile`}>{getMobileViewTbodyContent(tableRow, index)}</tr>);
      });
      returnHTML.push(
        <leaf-impression-wrapper key={4}>
          <div className="mobiletable-wrapper">
            <table className="table comparetable-mobile-view">
              <tbody>{mobileHTML}</tbody>
            </table>
          </div>
        </leaf-impression-wrapper>,
      );
      return returnHTML;
    }
    return ``;
  };

  const renderEnrollmentInfo = () => {
    const pdpPlanType = !state.planType?.includes('Medicare Advantage');
    return (
      pdpPlanType && (
        <leaf-alert variant="standalone" type="info" subtle open>
          <p>{HTMLReactParser(compareContent.t('misc.aepNextYearInfo'))}</p>
        </leaf-alert>
      )
    );
  };

  const renderEnrollButton = (type: string, plan: IMedicarePlan, index = -1) => {
    const fullPlan = `${plan.ContractID}-${plan.PlanID}-${plan.SegmentID}`;
    const impressionStr = `compare-plans-list^${index + 1}^${plan.PlanName}^${
      plan.PlanType === 1 ? planResultsContent.t('pdp.resultsHeader') : planResultsContent.t('ma.resultsHeader')
    }^${fullPlan}^${(plan.MedicalPremium + plan.DrugPremium).toFixed(2).toString()}`;
    const isCurrentYear = userData.year?.toString() === currentPlanYear.toString();
    const warnPlans: IWarningObj | undefined = enrollWarningPlans.find(
      (plan) => plan.year === userData!.year!.toString(),
    );
    let warnModal: string = '';
    if (warnPlans)
      Object.keys(warnPlans).forEach((key) => {
        if (warnPlans[`${key as keyof IWarningObj}`].indexOf(fullPlan) > -1) warnModal = key;
      });
    switch (true) {
      case warnModal.length < 1 && isCurrentYear && content.t('misc.currentYearShowEnroll') === 'true':
      case warnModal.length < 1 && !isCurrentYear && content.t('misc.nextYearShowEnroll') === 'true':
      case environment?.showAllEnroll && warnModal.length < 1:
        switch (type) {
          case 'table header':
            return (
              <p className="btn-enroll-wrapper">
                <leaf-button
                  size="sm"
                  part="enroll-btn"
                  class="leaf-u-display-none leaf-u-display-md-block enroll-btn"
                  onClick={EnrollDRX}
                  data-planId={plan.ID}
                  data-planname={plan.PlanName}
                  data-fullplan={`${plan.ContractID}-${plan.PlanID}-${plan.SegmentID}`}
                  data-en-content={enOnlyContent.plansView.enrollModalOpen}
                  data-custom={`eVar119:CSTD-3130^eVar120:compare-enroll-in-plan-drx-exit^prop13:ac23996^eVar160:Exit*${environment.endpoints.drxUrl}`}
                  data-impression-pass={impressionStr}
                  data-impression={impressionStr}
                >
                  {compareContent.t('misc.enrollPlanBtnText')}
                </leaf-button>
                <leaf-button
                  size="sm"
                  part="d-md-none"
                  class="leaf-u-display-md-none enroll-btn"
                  onClick={EnrollDRX}
                  data-planId={plan.ID}
                  data-planname={plan.PlanName}
                  data-fullplan={`${plan.ContractID}-${plan.PlanID}-${plan.SegmentID}`}
                  data-en-content={enOnlyContent.plansView.enrollModalOpen}
                  data-custom={`eVar119:CSTD-3130^eVar120:compare-enroll-in-plan-drx-exit^prop13:ac23996^eVar160:Exit*${environment.endpoints.drxUrl}`}
                  data-impression-pass={impressionStr}
                  data-impression={impressionStr}
                >
                  {compareContent.t('misc.enrollBtnMobileText')}
                </leaf-button>
              </p>
            );
          case 'sr-only':
            return (
              <p className="btn-enroll-wrapper">
                <leaf-button
                  size="sm"
                  class="enroll-btn"
                  onClick={EnrollDRX}
                  data-planId={plan.ID}
                  data-planname={plan.PlanName}
                  data-fullplan={`${plan.ContractID}-${plan.PlanID}-${plan.SegmentID}`}
                >
                  {compareContent.t('misc.enrollPlanBtnText')}
                </leaf-button>
              </p>
            );
          case 'sticky':
            return (
              <p className="btn-enroll-wrapper">
                <leaf-button
                  size="sm"
                  part="enroll-btn"
                  class="leaf-u-display-none leaf-u-display-md-block enroll-btn"
                  onClick={EnrollDRX}
                  data-planId={plan.ID}
                  data-planname={plan.PlanName}
                  data-fullplan={`${plan.ContractID}-${plan.PlanID}-${plan.SegmentID}`}
                  data-en-content={enOnlyContent.plansView.enrollModalOpen}
                  data-custom={`eVar119:CSTD-3130^eVar120:compare-enroll-in-plan-drx-exit^prop13:ac23996^eVar160:Exit*${environment.endpoints.drxUrl}`}
                  data-impression-pass={impressionStr}
                  data-impression={impressionStr}
                >
                  {compareContent.t('misc.enrollPlanBtnText')}
                </leaf-button>
                <leaf-button
                  size="sm"
                  part="d-md-none"
                  class="leaf-u-display-md-none enroll-btn"
                  onClick={EnrollDRX}
                  data-planId={plan.ID}
                  data-planname={plan.PlanName}
                  data-fullplan={`${plan.ContractID}-${plan.PlanID}-${plan.SegmentID}`}
                  data-en-content={enOnlyContent.plansView.enrollModalOpen}
                  data-custom={`eVar119:CSTD-3130^eVar120:compare-enroll-in-plan-drx-exit^prop13:ac23996^eVar160:Exit*${environment.endpoints.drxUrl}`}
                  data-impression-pass={impressionStr}
                  data-impression={impressionStr}
                >
                  {compareContent.t('misc.enrollBtnMobileText')}
                </leaf-button>
              </p>
            );
          default:
            break;
        }
        break;
      case isCurrentYear && content.t('misc.currentYearShowEnrollModal') === 'true':
      case isCurrentYear && content.t('misc.currentYearShowEnroll') === 'true' && warnModal.length > 0:
      case !isCurrentYear && content.t('misc.nextYearShowEnroll') === 'true' && warnModal.length > 0:
      case !isCurrentYear && content.t('misc.nextYearShowEnrollModal') === 'true':
      case environment?.showAllEnroll:
        switch (type) {
          case 'table header':
            return (
              <p className="btn-enroll-wrapper">
                <leaf-button
                  isButton={true}
                  size="sm"
                  class="leaf-u-display-none leaf-u-display-md-block open-enroll-modal enroll-btn"
                  onClick={openEnrollModal}
                  onFocus={focusEnrollBtn}
                  onBlur={blurEnrollBtn}
                  data-planType={plan.PlanType}
                  data-planId={plan.ID}
                  data-planname={plan.PlanName}
                  data-isExpired={state.isExpired}
                  data-en-content={enOnlyContent.plansView.enrollModalOpen}
                  data-custom="prop13:ac24776^eVar119:CSTD-3130^eVar120:link-compare-plans-enrollment-confirmation-modal-open"
                  data-impression-pass={impressionStr}
                  data-impression={impressionStr}
                  data-fullplan={`${plan.ContractID}-${plan.PlanID}-${plan.SegmentID}`}
                >
                  {compareContent.t('misc.enrollPlanBtnText')}
                </leaf-button>
                <leaf-button
                  isButton={true}
                  size="sm"
                  part="d-md-none"
                  class="leaf-u-display-md-none open-enroll-modal enroll-btn"
                  onClick={openEnrollModal}
                  onFocus={focusEnrollBtn}
                  onBlur={blurEnrollBtn}
                  data-planType={plan.PlanType}
                  data-planId={plan.ID}
                  data-planname={plan.PlanName}
                  data-isExpired={state.isExpired}
                  data-en-content={enOnlyContent.plansView.enrollModalOpen}
                  data-custom="prop13:ac24776^eVar119:CSTD-3130^eVar120:link-compare-plans-enrollment-confirmation-modal-open"
                  data-impression-pass={impressionStr}
                  data-impression={impressionStr}
                  data-fullplan={`${plan.ContractID}-${plan.PlanID}-${plan.SegmentID}`}
                >
                  {compareContent.t('misc.enrollBtnMobileText')}
                </leaf-button>
              </p>
            );
          case 'sr-only':
            return (
              <p className="btn-enroll-wrapper">
                <leaf-button
                  isButton={true}
                  size="sm"
                  class="enroll-btn"
                  onClick={EnrollDRX}
                  data-planType={plan.PlanType}
                  data-planId={plan.ID}
                  data-planname={plan.PlanName}
                  data-fullplan={`${plan.ContractID}-${plan.PlanID}-${plan.SegmentID}`}
                  data-isExpired={state.isExpired}
                >
                  {compareContent.t('misc.enrollPlanBtnText')}
                </leaf-button>
              </p>
            );
          case 'sticky':
            return (
              <p className="btn-enroll-wrapper">
                <leaf-button
                  isButton={true}
                  size="sm"
                  class="leaf-u-display-none leaf-u-display-md-block open-enroll-modal enroll-btn"
                  onClick={openEnrollModal}
                  onFocus={focusEnrollBtn}
                  onBlur={blurEnrollBtn}
                  part="enroll-btn"
                  data-planType={plan.PlanType}
                  data-planId={plan.ID}
                  data-planname={plan.PlanName}
                  data-isExpired={state.isExpired}
                  data-en-content={enOnlyContent.plansView.enrollModalOpen}
                  data-custom="prop13:ac24776^eVar119:CSTD-3130^eVar120:link-compare-plans-enrollment-confirmation-modal-open"
                  data-impression-pass={impressionStr}
                  data-impression={impressionStr}
                  data-fullplan={`${plan.ContractID}-${plan.PlanID}-${plan.SegmentID}`}
                >
                  {compareContent.t('misc.enrollPlanBtnText')}
                </leaf-button>
                <leaf-button
                  isButton={true}
                  size="sm"
                  class="leaf-u-display-md-none open-enroll-modal enroll-btn"
                  onClick={openEnrollModal}
                  onFocus={focusEnrollBtn}
                  onBlur={blurEnrollBtn}
                  part="d-md-none"
                  data-planType={plan.PlanType}
                  data-planId={plan.ID}
                  data-planname={plan.PlanName}
                  data-isExpired={state.isExpired}
                  data-en-content={enOnlyContent.plansView.enrollModalOpen}
                  data-custom="prop13:ac24776^eVar119:CSTD-3130^eVar120:link-compare-plans-enrollment-confirmation-modal-open"
                  data-impression-pass={impressionStr}
                  data-impression={impressionStr}
                  data-fullplan={`${plan.ContractID}-${plan.PlanID}-${plan.SegmentID}`}
                >
                  {compareContent.t('misc.enrollBtnMobileText')}
                </leaf-button>
              </p>
            );
          default:
            break;
        }
        break;
    }
    return;
  };

  const toggleExtraContent = (event: Event) => {
    const { currentTarget } = event;
    const contentWrapper = (currentTarget as HTMLElement).closest('td')?.querySelector('.body-content-wrapper');
    let buttonText = '';
    if (contentWrapper?.classList.contains('showLess')) {
      contentWrapper?.classList.remove('showLess');
      buttonText = (currentTarget as HTMLElement).dataset.lesstext || '';
    } else {
      contentWrapper?.classList.add('showLess');
      buttonText = (currentTarget as HTMLElement).dataset.moretext || '';
    }
    (currentTarget as HTMLElement)!.textContent = buttonText;
    setFixedColHeight();
  };

  const fixedColumn = (element?: HTMLDivElement | null | Event) => {
    const compareTable = (element as HTMLElement)?.querySelector('.comparetable-mobile-view');
    if (compareTable?.querySelectorAll('tbody tr')) {
      if (isMobile() && !state.mobileHeightSet) {
        if (compareTable) {
          const compareTableWrapper = compareTable?.closest('div') as HTMLElement;
          compareTable?.querySelectorAll('.col-fixed')?.forEach((col) => {
            col.removeAttribute('style');
          });

          const lineHeight = parseInt(
            window.getComputedStyle(compareTable?.querySelector('tbody tr') as Element).getPropertyValue('line-height'),
            10,
          );
          const cellMaxHeight = lineHeight * 6;
          compareTable?.querySelectorAll('tbody tr').forEach((row) => {
            row.querySelectorAll('.body-content-wrapper').forEach((wrapper: Element) => {
              if (wrapper.clientHeight > cellMaxHeight) {
                if (wrapper.closest('td')?.querySelectorAll('.showbutton').length === 0) {
                  (wrapper as HTMLElement).classList.add('showLess');
                  const dynamicEl = document.createElement('leaf-button-link') as HTMLElement;
                  dynamicEl.setAttribute('class', 'showbutton');
                  dynamicEl.setAttribute('aria-hidden', 'true');
                  dynamicEl.setAttribute('data-moretext', compareContent.t('misc.showMoreText'));
                  dynamicEl.setAttribute('data-lesstext', compareContent.t('misc.showLessText'));
                  dynamicEl.textContent = compareContent.t('misc.showMoreText');
                  wrapper.after(dynamicEl);
                }
              }
            });
          });
          const fixedWidth = compareTable!.querySelector('.col-fixed')!.clientWidth;
          compareTable?.querySelectorAll('.col-fixed').forEach((col, index) => {
            let row = col.closest('tr');
            col.querySelectorAll('tr').forEach((tr) => {
              if ((tr!.firstChild as HTMLElement)!.offsetHeight > (row!.firstChild as HTMLElement)!.offsetHeight)
                row = tr;
            });
            const rowHeight = index === 0 ? '100%;' : `${(row!.firstChild as HTMLElement).offsetHeight + 40}px;`;
            row?.setAttribute('style', `height: ${rowHeight}`);
            col.setAttribute('style', `position: absolute; left: 0px; width: ${fixedWidth}px; height: ${rowHeight}`);
          });
          compareTableWrapper?.setAttribute('style', `padding-left: ${fixedWidth}px`);
          state.mobileHeightSet = true;
          setState((prevState) => ({ ...prevState, mobileHeightSet: true }));
        } else {
          stickyBarWidth(true);
        }
      }
    } else {
      setTimeout(() => fixedColumn(element), 100);
    }
  };

  const setFixedColHeight = () => {
    const compareTable = rootRef.current?.querySelector('.comparetable-mobile-view');
    compareTable?.querySelectorAll('.col-fixed').forEach((col, index) => {
      let row = col.closest('tr');
      col.querySelectorAll('tr').forEach((tr) => {
        if ((tr!.firstChild as HTMLElement)!.offsetHeight > (row!.firstChild as HTMLElement)!.offsetHeight) row = tr;
      });
      row?.setAttribute('style', `height: 100%`);
      const rowHeight = index === 0 ? '100%;' : `${(row!.firstChild as HTMLElement)!.offsetHeight + 32}px;`;
      row?.setAttribute('style', `height: ${rowHeight}`);
      (col as HTMLElement).style.height = `${rowHeight}`;
    });
  };

  const EnrollDRX = (event: Event) => {
    rootRef.current?.querySelector('.enroll-spinner')?.removeAttribute('hidden');
    event.stopPropagation();
    const target = event.target as HTMLElement;
    target.dataset!.planname!.indexOf('Secure') > -1
      ? document?.querySelector('#SecureModalContent')?.removeAttribute('hidden')
      : document?.querySelector('#SecureModalContent')?.setAttribute('hidden', '');
    const data = target.dataset?.planid;
    const warnPlans: IWarningObj | undefined = enrollWarningPlans.find(
      (plan) => plan.year === userData?.year?.toString(),
    );
    const fullPlan = target.dataset?.fullplan || '';
    let warnModal: string = '';
    if (warnPlans)
      Object.keys(warnPlans).forEach((key) => {
        if (warnPlans[`${key as keyof IWarningObj}`].indexOf(fullPlan) > -1) warnModal = key;
      });
    if (
      (warnModal.length < 1 &&
        target.classList.contains('enroll-modal-btn') &&
        ((currentPlanYear.toString() === userData.year.toString() &&
          content.t('misc.currentYearShowEnrollModal') !== 'true') ||
          (currentPlanYear.toString() !== userData.year.toString() &&
            content.t('misc.nextYearShowEnrollModal') !== 'true'))) ||
      (warnModal.length < 1 && content.t('misc.nextYearShowEnroll') == 'true')
    ) {
      const planType = state.planType?.includes('Medicare Advantage') ? 'ma' : 'pdp';
      customTagEvent(target, `${planType}-plan-click`);
      enroDRX({
        session: userData,
        pharmacyList: pharmacyData.getPharmacyList(),
        selectedPlan: data,
      }).catch(() => {
        hasErrorState.setApiDownError(true);
      });
    } else if (warnModal.length > 0) {
      setState((prevState) => ({
        ...prevState,
        enrollWarningContent: planResultsContent.t(`misc.enrollWarning${warnModal}`),
      }));
      (document?.querySelector(`#enroll-warning-modal-${fullPlan}`) as any)?.show();
    } else {
      const compareModal = document?.querySelector('#EnrollThisPlanCompareView');
      compareModal?.querySelector('.enroll-modal-btn')?.setAttribute('data-planid', data || '');
      (compareModal as any)?.show();
      rootRef.current?.querySelector('.enroll-spinner')?.setAttribute('hidden', '');
    }
  };

  const print = () => {
    window.print();
  };

  const focusEnrollBtn = (event: Event) => {
    const target = event.target as HTMLElement;
    target?.closest('.premium-section')?.removeAttribute('aria-hidden');
  };

  const blurEnrollBtn = (event: Event) => {
    const target = event.target as HTMLElement;
    target?.closest('.premium-section')?.setAttribute('aria-hidden', 'true');
  };

  const dismissModal = (isRemove: boolean) => {
    if (isRemove) {
      if (rootRef.current?.querySelector('#EnrollThisPlanCompareView')) {
        window.scrollTo(0, 0);
      }
    }
    rootRef.current?.querySelector('leaf-modal')?.hide();
  };

  // // this might need to be redone with modeal component implementation..
  const enrollModal = (planType: string | number) => {
    const isCurrentYear = userData.year?.toString() === currentPlanYear.toString();
    const thisContent = planDetailsContent.t('modal', { returnObjects: true });
    const renderContent = () => {
      if (state.isExpired) {
        if (planType === 1 && thisContent.transferNewPDPPlan) {
          return <p>{HTMLReactParser(thisContent.transferNewPDPPlan)}</p>;
        } else if (!(planType === 1) && thisContent.transferNewMAPlan) {
          return <p>{HTMLReactParser(thisContent.transferNewMAPlan)}</p>;
        }
      } else {
        if (planType === 1 && thisContent.transferPDPPlan) {
          return <p>{HTMLReactParser(thisContent.transferPDPPlan)}</p>;
        } else if (!(planType === 1) && thisContent.transferPlan) {
          return (
            <>
              <p>{HTMLReactParser(thisContent.transferPlan)}</p>
              <p hidden id="SecureModalContent">
                {HTMLReactParser(thisContent.transferSecurePlan)}
              </p>
            </>
          );
        }
      }
      return '';
    };
    switch (true) {
      case isCurrentYear && content.t('misc.currentYearShowEnrollModal') === 'true':
      case isCurrentYear && content.t('misc.currentYearShowEnroll') === 'true':
      case !isCurrentYear && content.t('misc.nextYearShowEnroll') === 'true':
      case !isCurrentYear && content.t('misc.nextYearShowEnrollModal') === 'true':
      case environment?.showAllEnroll:
        return (
          <leaf-modal
            has-focus-trap="true"
            role="dialog"
            aria-modal="true"
            id="EnrollThisPlanCompareView"
            size="modal-md"
            aria-label={planDetailsContent.t('modal.ariaLabel')}
            hasclosebutton=""
          >
            <h4 slot="heading">{planDetailsContent.t('modal.heading')}</h4>
            {currentPlanYear.toString() === userData.year.toString() && renderContent()}

            <p>{planDetailsContent.t('modal.confirmMessage')}</p>
            <div slot="cta">
              <leaf-flex-container>
                <div className="enroll-modal">
                  <div>
                    <leaf-button
                      slot="footer"
                      isButton={true}
                      size="small"
                      class="enroll-btn enroll-modal-btn"
                      onClick={EnrollDRX}
                      data-custom={`prop13:ac24777^eVar119:CSTD-3130^prop20:Plan Enrollment Confirmation^eVar160:exit*${environment.endpoints.drxUrl}^eVar120:link-compare-plans-enrollment-confirmation-modal-confirm-exit`}
                      data-en-content={enOnlyContent.plansView.enrollExit.replace('${planYear}', userData.year)}
                    >
                      {planDetailsContent.t('modal.yesEnroll').replace('${year}', userData.year)}
                    </leaf-button>
                  </div>
                  <leaf-button-link
                    class="cancel-button"
                    isButton={true}
                    onClick={dismissModal.bind(this, false)}
                    data-custom="prop13:ac24778^eVar119:CSTD-3130^prop20:Plan Enrollment Confirmation^eVar120:link-compare-plans-enrollment-confirmation-cancel-modal-close"
                    data-en-content={enOnlyContent.plansView.enrollModalCancel}
                  >
                    {planDetailsContent.t('modal.noCancel')}
                  </leaf-button-link>
                </div>
              </leaf-flex-container>
            </div>
            <div className="enroll-spinner" hidden>
              <leaf-spinner role="alert" aria-live="assertive" size="large" mode="indeterminate">
                <span>{compareContent.t('misc.loading')}</span>
              </leaf-spinner>
            </div>
          </leaf-modal>
        );
      default:
        return ``;
    }
  };

  const openEnrollModal = (event: Event) => {
    const target = event.target as HTMLElement;
    const modal = rootRef.current?.querySelector('#EnrollThisPlanCompareView');
    const enrollBtn = modal?.querySelector('.enroll-btn') as HTMLElement;
    modal?.setAttribute(
      'data-impression',
      target.getAttribute('data-impression-pass')?.replaceAll('shop-plans-card-list', 'compare-plans-list') || '',
    );
    enrollBtn!.dataset.planid = target.dataset.planid;
    target?.dispatchEvent(
      new CustomEvent('leaf.show', {
        bubbles: true,
        composed: true,
        detail: {
          modalToggle: true,
          modalTarget: 'EnrollThisPlanCompareView',
          target: (target as HTMLElement)?.shadowRoot?.querySelector('button'),
        },
      }),
    );

    const planExpired = rootRef.current?.querySelector('#EnrollThisPlanCompareView')?.querySelector('.plan-expired');
    const planNotExpired = rootRef.current
      ?.querySelector('#EnrollThisPlanCompareView')
      ?.querySelector('.plan-notexpired');
    const planPDPExpired = rootRef.current
      ?.querySelector('#EnrollThisPlanCompareView')
      ?.querySelector('.planPDP-expired');
    const planPDPNotExpired = rootRef.current
      ?.querySelector('#EnrollThisPlanCompareView')
      ?.querySelector('.planPDP-notexpired');

    planExpired?.setAttribute('hidden', 'true');
    planNotExpired?.setAttribute('hidden', 'true');
    planPDPExpired?.setAttribute('hidden', 'true');
    planPDPNotExpired?.setAttribute('hidden', 'true');

    if (target.dataset.plantype !== '1') {
      if (target.dataset.isexpired === 'true') {
        planExpired?.removeAttribute('hidden');
      }

      if (target.dataset.isexpired !== 'true') {
        planNotExpired?.removeAttribute('hidden');
      }
    }

    if (target.dataset.plantype === '1') {
      if (target.dataset.isexpired === 'true') {
        planPDPExpired?.removeAttribute('hidden');
      }

      if (target.dataset.isexpired !== 'true') {
        planPDPNotExpired?.removeAttribute('hidden');
      }
    }
  };

  const backToCompareBox = () => {
    const urlObj = new URL(window.location.origin);
    urlObj.pathname = '/';

    if (userData.location?.zip && userData.location?.fip) {
      urlObj.searchParams.set('zip', userData.location.zip);
      urlObj.searchParams.set('fip', userData.location.fip);
    }

    const urlParams = new URLSearchParams(window.location.search);
    const lng = urlParams.get('lng');
    if (urlParams.has('lng') && lng) {
      urlObj.searchParams.set('lng', lng);
    }

    if (userData?.lastTabVisit && userData.lastTabVisit !== 'ma') {
      urlObj.hash = userData.lastTabVisit;
    }
    urlObj.searchParams.set('PlanYear', userData.year?.toString() || '2025');

    const path = urlObj.href.replace(urlObj.origin, '');
    navigate(path, { ...routeLocation, replace: true });
  };

  const editInfo = () => {
    const urlObj = new URL(window.location.href);
    urlObj.pathname = mydrugselector.dosageList.length > 0 ? '/prescriptions/view-list' : '/prescriptions/';

    const path = urlObj.href.replace(urlObj.origin, '');
    const pathName = window.location.href.replace(window.location.origin, '');
    navigate(path, { state: { pathname: pathName, from: 'comparePlanView' } });
  };

  const viewDetails = (event: Event) => {
    const target = event.currentTarget;
    const planId = (target as HTMLElement).dataset.planid;
    if (planId) {
      const urlObj = new URL(window.location.href);
      urlObj.pathname = '/plan-details';
      urlObj.searchParams.set('PlanID', planId);
      urlObj.searchParams.set('PlanYear', urlObj.searchParams.get('PlanYear') || '2025');
      urlObj.hash = (target as HTMLElement).dataset.tab || '';

      if (window.location.hash) {
        urlObj.searchParams.set('previousAnchor', window.location.hash.substring(1));
      }

      const path = urlObj.href.replace(urlObj.origin, '');
      navigate(path, { state: { from: 'comparePlans' } });
    }
  };

  const addBackListener = (el: string, tableVar = '.table-responsive-md') => {
    const elements = [];
    isMobile()
      ? elements.push(document?.querySelector('.comparetable-mobile-view')?.querySelectorAll(el))
      : document
          ?.querySelector(tableVar)
          ?.querySelectorAll('chc-table')
          .forEach((table) => elements.push(table.shadowRoot?.querySelectorAll(el)));
    // forces enroll buttons to finish rendering before adding back click events
    if (
      (elements && elements.length > 0 && elements[0] && el !== '.enroll-btn') ||
      (el === '.enroll-btn' && elements[0] && elements[0][0]) ||
      (el === '.enroll-btn' && !hasEnroll)
    ) {
      switch (el) {
        case '.prescription-link':
          elements[0]?.forEach((link) => link.addEventListener('click', editInfo));
          break;
        case '.enroll-btn':
          elements[0]?.forEach((link) => link.addEventListener('click', EnrollDRX));
          break;
        case '.plan-name':
        case '[data-tab="prescriptions"]':
          elements[0]?.forEach((link) => link.addEventListener('click', viewDetails));
          break;
        default:
          elements[0]?.forEach((link) => link.addEventListener('click', toggleExtraContent));
      }
    } else {
      setTimeout(() => {
        addBackListener(el);
      }, 100);
    }
  };

  const handleTableWidth = () => {
    const stickyBar = document?.querySelector('.sticky-bar');
    const compareTable = document.querySelector('chc-table.compare-table')?.shadowRoot?.querySelector('table');
    if (!isMobile('md')) {
      if (compareTable) {
        const wrapperWidth = document.querySelector('chc-table.compare-table')?.getBoundingClientRect().width;
        const tableWidth = compareTable?.getBoundingClientRect().width + 2;
        if (wrapperWidth && wrapperWidth < tableWidth) {
          (stickyBar?.querySelector('chc-table') as HTMLElement).style.width = `${tableWidth}px`;
          (document.querySelector('chc-table.compare-table') as HTMLElement).style.width = `${tableWidth}px`;
        } else {
          stickyBar?.querySelector('chc-table').style.removeProperty('width');
          (document.querySelector('chc-table.compare-table') as HTMLElement).style.removeProperty('width');
        }
      } else {
        setTimeout(() => {
          handleTableWidth();
        }, 200);
      }
    }
  };

  const returnSearch = (year = '2025') => {
    const urlObj = new URL(window.location.href);
    const searchParams: ISearchParams = getParams(location.search);
    const sanitizedParams = JSON.parse(sanitize(JSON.stringify(searchParams)));

    urlObj.pathname = '/';
    urlObj.searchParams.set('zip', sanitizedParams.zip);
    urlObj.searchParams.set('fip', sanitizedParams.fip);
    urlObj.searchParams.set('PlanYear', year.toString());
    urlObj.searchParams.delete('PlanID');

    if (sanitizedParams?.previousAnchor) {
      urlObj.hash = sanitizedParams.previousAnchor;
    }

    mySelectedPlans.compareSelected = [];
    const path = urlObj.href.replace(urlObj.origin, '');
    navigate(path);
  };

  const setYear = (year: number) => {
    dispatchEvent(
      new CustomEvent('sendYear', {
        bubbles: true,
        composed: true,
        cancelable: true,
        detail: { year: year },
      }),
    );
    returnSearch(year.toString());
  };

  const addWindowResize = () => {
    //TODO: Clean this up
    const windowWidth = window.innerWidth;
    window.addEventListener('resize', () => {
      if (windowWidth !== window.innerWidth) {
        let el = document.querySelector('#compare-plans');
        if (el) {
          fixedColumn(el as any);
          stickyBarWidth(true);
          addBackListener('[data-tab="prescriptions"]');
          addBackListener('.prescription-link');
          addBackListener('.plan-name');
          addBackListener('.enroll-btn');
          addBackListener('.showbutton');
          handleTableWidth();
        } else {
          setTimeout(() => {
            el = document.querySelector('#compare-plans');
            addWindowResize();
          }, 100);
        }
      }
    });
  };

  const hasDsnp = (planName: string | string[]) => {
    return planName.indexOf('D-SNP') > -1;
  };

  const dsnpContent = (plan: IMedicarePlan) => {
    return (
      <>
        {plan.PlanName.indexOf('D-SNP') > -1 && (
          <p part="font-normal" className="font-normal">
            {plan!
              .ExtraData!.filter((field: { Name: string }) => field.Name === 'EducationalContent')[0]
              .Value?.replaceAll('<br>', ' ')}
          </p>
        )}
      </>
    );
  };

  useEffect(() => {
    addWindowResize();
    if (mySelectedPlans.compareSelected) {
      const updateInstance: IUserSession = {
        ...userData,
      };
      const previousViewPath = { ...userData.previousViewPath, extraHelpView: routeLocation.pathname };
      userData.setPreviousViewPath(previousViewPath);
      userData.setStatus(updateInstance);
      const updateAnalytics: IAnalytics = {
        ...analyticsData,
        currentPageTitle: compareContent.t('misc.pageTitle'),
        currentView: 'comparePlansView',
        year: userData.year || 2025,
      };
      analyticsData.setStatus(updateAnalytics);
      let el = document.querySelector('#compare-plans');
      el
        ? fixedColumn(el as any)
        : setTimeout(() => {
            el = document.querySelector('#compare-plans');
            fixedColumn(el as any);
          }, 100);
      setTimeout(() => {
        window.addEventListener('scroll', updatedFixedMenu.bind(this));
        setState((prevState) => ({ ...prevState, renderMobileView: true }));
        el?.querySelectorAll('.plan-name').forEach((button: Element) => {
          button.addEventListener('click', viewDetails.bind(this));
        });
        //TODO: Clean this up
        addBackListener('[data-tab="prescriptions"]');
        addBackListener('.prescription-link');
        addBackListener('.plan-name');
        addBackListener('.enroll-btn');
        addBackListener('.showbutton');
        handleTableWidth();
      }, 2000);
    } else {
      const urlObj = new URL(window.location.href);
      urlObj.pathname = '/';

      const path = urlObj.href.replace(urlObj.origin, '');
      navigate(path, { replace: true });
    }
    const setImpression = () => {
      const arr = document
        .querySelector('leaf-impression-wrapper')
        ?.querySelectorAll('.plan-name[data-impression]') as unknown as Element[];
      if (arr?.length && arr) {
        analyticsData.setImpressionArr([...arr]);
        analyticsData.setImpressionType('comparePlans');
      } else {
        setTimeout(() => setImpression(), 200);
      }
    };
    setImpression();
  }, []);

  useEffect(() => {
    (async () => {
      window.addEventListener('scroll', updatedFixedMenu.bind(this));

      // need to set this to see if "inview" = true otherwise it fires from load
      // also if set to false we want to blank this screen somehow so probably should code for that aswell
      // if (props.inView) {
      if (userData.session) {
        if (mySelectedPlans.compareSelected) {
          rootRef.current?.querySelector('.loading')?.removeAttribute('hidden');
          rootRef.current?.querySelector('compare-plan-error')?.setAttribute('hidden', 'true');
          setState((prevState) => ({ ...prevState, selectedPlans: [] }));
          window.scrollTo(0, 0);
          let errorData = false;
          const tempdata: Array<any> = [];
          let selectedPharmResponse;
          if (pharmacyData.selectedPharmacyName !== 'Mail Order') {
            const pharmacyList = pharmacyData.getPharmacyList();
            const pharmResponses = [];
            if (pharmacyList !== null) {
              for (const pharmacytoset of pharmacyList) {
                const pharmresponse = await replacePharma({ session: userData.session, pharmacyList: [pharmacytoset] });
                pharmResponses.push(pharmresponse);
              }
            }
            pharmResponses.forEach((pharmObj: any) => {
              if (pharmObj[0].Name === pharmacyData.selectedPharmacyName) {
                pharmacyList?.forEach((pharmData: { PharmacyID: any; PharmacyIDType: number }) => {
                  if (pharmData.PharmacyID === pharmObj[0].PharmacyID) {
                    selectedPharmResponse = pharmData;
                  }
                });
              }
            });
            if (selectedPharmResponse) {
              await replacePharma({ session: userData.session, pharmacyList: [selectedPharmResponse] });
            }
          }
          await Promise.all(
            mySelectedPlans.compareSelected.map(async (planToCompare: IMedicarePlan) => {
              await planDetailAPI({
                userData: userData,
                planID: planToCompare.ID,
              })
                .then((value) => {
                  tempdata.push(value);
                })
                .catch(() => {
                  errorData = true;
                });
            }),
          ).then(() => {
            rootRef.current?.querySelector('.loading')?.setAttribute('hidden', 'true');
            if (!errorData) {
              if (mySelectedPlans.compareSelected) {
                mySelectedPlans.compareSelected.map((planToCompare: IMedicarePlan) => {
                  setState((prevState) => ({
                    ...prevState,
                    selectedPlans: [...prevState.selectedPlans, tempdata.find((plan) => plan.ID === planToCompare.ID)],
                  }));
                });
                // Code needed for dual plan year - donot delete
                state.selectedPlans.forEach((plan: IMedicarePlan) => {
                  state.isExpired = false;
                  // Code needed for dual plan year - donot delete
                  if (plan.PlanType !== 1) {
                    const matchedPlan = expiringPlans.plans.find(
                      (expiringPlan) =>
                        expiringPlan.ContractID === plan.ContractID &&
                        expiringPlan.PlanID === plan.PlanID &&
                        expiringPlan.SegmentID === plan.SegmentID,
                    );
                    state.isExpired = Boolean(matchedPlan);
                  } else if (plan.PlanName.includes('Essential')) {
                    state.isExpired = true;
                  }
                });
                setState((prevState) => ({ ...prevState, isExpired: state.isExpired }));
              }
            } else {
              rootRef.current?.querySelector('compare-plan-error')?.removeAttribute('hidden');
            }
            setState((prevState) => ({
              ...prevState,
              planType:
                tempdata[0].PlanType !== 1 ? compareContent.t('misc.maPlans') : compareContent.t('misc.pdpPlans'),
              calloutInfoHeading:
                tempdata[0].PlanType !== 1
                  ? compareContent.t('misc.maCalloutHeadingText')
                  : compareContent.t('misc.pdpCalloutHeadingText'),
            }));
            // PlansView.setImpressionCompare();
          });
        }
      }
      setTimeout(async () => {
        setState((prevState) => ({ ...prevState, renderMobileView: true }));
      }, 100);
    })();
    return () => {
      window.removeEventListener('scroll', updatedFixedMenu.bind(this));
      window.removeEventListener('resize', fixedColumn);
    };
  }, [userData.session, mySelectedPlans.compareSelected]);

  return (
    <div id="compare-plans" ref={rootRef}>
      {(state.planType.includes('Medicare Advantage') && compareContent.t('misc.showOEPBanner') === 'TRUE' && (
        <leaf-alert variant="standalone" type="info" subtle open class="next-year-banner">
          {compareContent.t('misc.infoBannerNextYear')}
        </leaf-alert>
      )) ||
        ''}
      {userData.year?.toString() === '2024' && (
        <leaf-alert variant="standalone" type="info" subtle open>
          <h3 slot="heading">
            {compareContent.t('misc.infoBannerCurrentYear')}&nbsp;
            {state?.selectedPlans.find((Plan) => Plan.PlanName.indexOf('Secure') > 0) &&
              HTMLReactParser(compareContent.t('misc.infoBannerCurrentYearAssurance'))}
            <leaf-button-link isButton={true} onClick={setYear.bind(this, 2025)}>
              {compareContent.t('misc.infoBannerLink')}
            </leaf-button-link>
          </h3>
        </leaf-alert>
      )}
      {state.selectedPlans.length > 0 ? (
        <>
          <div className="top-nav-links">
            <div className="back-btn">
              <leaf-button variant="tertiary" classes="leaf-u-link--no-underline" onClick={backToCompareBox}>
                <leaf-icon set="system" name="keyboard-arrow-left"></leaf-icon>
                {compareContent.t('misc.backBtn')}
              </leaf-button>
            </div>
            <leaf-button variant="secondary" onClick={print}>
              <leaf-icon slot="icon-before" label="print" set="system" name="print"></leaf-icon>
              &nbsp;{compareContent.t('misc.printTable')}
            </leaf-button>
          </div>
          <h1 className="compare-details location leaf-u-typography-headline-default">
            {compareContent.t('misc.compareText')} {state.selectedPlans.length} {state.planType}
          </h1>
          {/* Code needed for dual plan year */}
          {renderEnrollmentInfo()}
          <div className="compare-details-section">
            <div className="table-responsive-md">
              <leaf-impression-wrapper key={1}>
                {enrollModal(state.planType)}
                <chc-table
                  class="compare-table"
                  table-header-color="orange"
                  colwidths={
                    state.selectedPlans.length === 3 ? '["25%", "25%", "25%", "25%"]' : '["33.33%", "33.33%", "33.33%"]'
                  }
                >
                  <table slot="table">
                    <thead>
                      <tr className="plan-name-details">
                        <th part="thead-th"></th>
                        {state.selectedPlans.map((plan: IMedicarePlan, index: number) => {
                          const splitNum = (plan.MedicalPremium + plan.DrugPremium).toFixed(2).toString().split('.');
                          return (
                            <th
                              className={index === 0 ? 'firstplan' : ''}
                              scope="col"
                              key={index}
                              part={hasDsnp(plan.PlanName) ? 'vertical-top' : 'thead-th'}
                            >
                              <div className="plan-details">
                                <p>
                                  <leaf-button-link
                                    part="plan"
                                    class="plan-name leaf-u-typography-label-large"
                                    onClick={viewDetails}
                                    data-planid={plan.ID}
                                    data-planname={plan.PlanName}
                                    data-custom="prop13:ac23995^eVar120:compare-plan-name-cta"
                                    data-impression={`compare-plans-list^${index + 1}^${plan.PlanName}^${
                                      state.planType
                                    }^${plan.ContractID + '-' + plan.PlanID + '-' + plan.SegmentID}^${splitNum[0]}.${
                                      splitNum[1]
                                    }`}
                                  >
                                    <span className="plan-heading">{plan.PlanName}</span>
                                  </leaf-button-link>
                                </p>
                                <div className="premium-section" aria-hidden="true">
                                  <span part="premium-label" className="premium-label">
                                    {compareContent.t('misc.monthlyPremiumLabel')}
                                  </span>
                                  <br />
                                  <span part="premium-price" className="premium-price">
                                    {supNumber(plan.MedicalPremium + plan.DrugPremium)}
                                  </span>
                                  {renderEnrollButton('table header', plan, index)}
                                  {dsnpContent(plan)}
                                </div>
                              </div>
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row" part="th" className="leaf-u-background-gray">
                          <p>
                            <strong>{compareContent.t('costSection.estDrugCostLabel')}</strong>
                          </p>
                          <span part="font-normal">
                            {compareContent.t('costSection.estEditLabel')}&nbsp;{mydrugselector.dosageList.length}&nbsp;
                            {compareContent.t('costSection.estEditLabelEnd')}
                          </span>
                          <br />
                          <leaf-button-link class="prescription-link" onClick={editInfo}>
                            {compareContent.t('misc.addEditBtnLink')}
                          </leaf-button-link>
                        </th>
                        {state.selectedPlans.map((plan: IMedicarePlan, index: number) => {
                          const splitNum = (plan.MedicalPremium + plan.DrugPremium).toFixed(2).toString().split('.');
                          return (
                            <td key={`${index}-estimate`} part="tbody-td">
                              {renderEst(plan)}
                              <p>
                                <leaf-button-link
                                  onClick={viewDetails}
                                  data-planid={plan.ID}
                                  data-planname={plan.PlanName}
                                  data-tab="prescriptions"
                                  data-impression={`compare-plans-list^${index + 1}^${plan.PlanName}^${
                                    state.planType
                                  }^${plan.ContractID + '-' + plan.PlanID + '-' + plan.SegmentID}^${splitNum[0]}.${
                                    splitNum[1]
                                  }`}
                                >
                                  {compareContent.t('misc.viewDetails')}
                                </leaf-button-link>
                              </p>
                            </td>
                          );
                        })}
                      </tr>
                      <tr>
                        <th scope="row" part="th" className="leaf-u-background-gray">
                          <p>
                            <strong>{compareContent.t('costSection.annualCostLabel')}</strong>
                          </p>
                          <span part="font-normal">{compareContent.t('costSection.annualCostEditLabel')}</span>
                          <br />
                          {(userData.year?.toString() === currentPlanYear.toString() && (
                            <>
                              <span part="font-normal">
                                {HTMLReactParser(
                                  compareContent
                                    .t('misc.remainderCurrentYearText')
                                    .replaceAll('${year}', userData.year),
                                )}
                              </span>
                              <br />
                            </>
                          )) || (
                            <>
                              <span part="font-normal">
                                {HTMLReactParser(
                                  compareContent.t('misc.remainderNextYearText').replaceAll('${year}', userData.year),
                                )}
                              </span>
                              <br />
                            </>
                          )}
                          <leaf-button-link class="prescription-link" onClick={editInfo}>
                            {compareContent.t('misc.addEditBtnLink')}
                          </leaf-button-link>
                        </th>
                        {state.selectedPlans.map((plan, index: number) => {
                          return (
                            <td key={`${index}-total`} part="tbody-td">
                              {renderTotalCost(plan)}
                            </td>
                          );
                        })}
                      </tr>
                      {renderBenefits()}
                      {renderTierDisplay()}
                      {renderPlanDocuments()}
                    </tbody>
                  </table>
                </chc-table>
              </leaf-impression-wrapper>
              <leaf-impression-wrapper key={2}>
                <ul className="visually-hidden">
                  {state.selectedPlans.map((plan: IMedicarePlan, index: number) => {
                    return (
                      <li key={index}>
                        <div className="plan-details">
                          <p>
                            <leaf-button-link
                              class="plan-name"
                              onClick={viewDetails}
                              data-planid={plan.ID}
                              data-planname={plan.PlanName}
                              data-custom="prop13:ac23995^eVar120:compare-plan-name-cta"
                            >
                              <span className="plan-heading">{plan.PlanName}</span>
                            </leaf-button-link>
                          </p>
                          <div className="premium-section">
                            {compareContent.t('misc.monthlyPremiumLabel')}&nbsp;
                            <span className="number">{supNumber(plan.MedicalPremium + plan.DrugPremium)}</span>
                            {renderEnrollButton('sr-only', plan)}
                            {dsnpContent(plan)}
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
                {renderMobileViewCompareTable()}
              </leaf-impression-wrapper>
              <leaf-impression-wrapper key={3}>
                <div className="table-responsive-md plan-name-details sticky-bar">
                  <div className="grid">
                    <chc-table
                      class="table"
                      aria-hidden="true"
                      table-header-color="orange"
                      colwidths={state.selectedPlans.length === 3 ? '["25%"]' : '["33.33%"]'}
                    >
                      <table slot="table">
                        <thead>
                          <tr>
                            <td part="sticky-td"></td>
                            {state.selectedPlans.map((plan: IMedicarePlan, index: number) => {
                              const splitNum = (plan.MedicalPremium + plan.DrugPremium)
                                .toFixed(2)
                                .toString()
                                .split('.');
                              return (
                                <th
                                  part={hasDsnp(plan.PlanName) ? 'vertical-top' : 'thead-th'}
                                  className={index === 0 ? 'firstplan' : ''}
                                  scope="col"
                                  key={index}
                                >
                                  <div className="plan-details">
                                    <p className="plan-name">
                                      <leaf-button-link
                                        part="plan"
                                        onClick={viewDetails}
                                        class="plan-name no-imp-arr"
                                        data-planid={plan.ID}
                                        data-custom="prop13:ac23995^eVar120:compare-plan-name-cta"
                                        data-impression={`compare-plans-list^${index + 1}^${plan.PlanName}^${
                                          state.planType
                                        }^${plan.ContractID + '-' + plan.PlanID + '-' + plan.SegmentID}^${
                                          splitNum[0]
                                        }.${splitNum[1]}`}
                                      >
                                        <span className="plan-heading">{plan.PlanName}</span>
                                      </leaf-button-link>
                                    </p>
                                    <div className="premium-section" aria-hidden="true">
                                      <span part="premium-label" className="premium-label">
                                        {compareContent.t('misc.monthlyPremiumLabel')}&nbsp;
                                      </span>
                                      <span part="premium-price" className="premium-price">
                                        {supNumber(plan.MedicalPremium + plan.DrugPremium)}
                                      </span>
                                      {renderEnrollButton('sticky', plan, index)}
                                      {dsnpContent(plan)}
                                    </div>
                                  </div>
                                </th>
                              );
                            })}
                          </tr>
                        </thead>
                      </table>
                    </chc-table>
                  </div>
                </div>
              </leaf-impression-wrapper>
            </div>
            <div className="row iw_row bottom-print-btn">
              <div className="columns iw_columns large-7">
                <div className="btn-wrapper">
                  <leaf-button variant="secondary" onClick={print}>
                    {compareContent.t('misc.printTable')}
                    <leaf-icon slot="icon-before" set="system" name="print"></leaf-icon>
                  </leaf-button>
                </div>
              </div>
            </div>
            <div className="row iw_row">
              <div className="columns iw_columns large-7">
                <p className="micro-text">{HTMLReactParser(compareContent.t('misc.copayInfo'))}</p>
                <p className="micro-text">
                  {HTMLReactParser(compareContent.t('misc.drugEstimateInfo1'))}
                  <leaf-button-link onClick={showExtraHelp}>
                    {HTMLReactParser(compareContent.t('misc.drugEstimateLink'))}
                  </leaf-button-link>
                  .
                </p>
                <p className="micro-text">{HTMLReactParser(compareContent.t('misc.drugEstimateInfo2'))}</p>
              </div>
            </div>
            {state.selectedPlans.map((plan) => {
              return (
                <EnrollWarningModal
                  key={plan.ID}
                  fullplan={`${plan.ContractID}-${plan.PlanID}-${plan.SegmentID}`}
                  planID={plan.ID}
                  enrollWarningContent={state.enrollWarningContent || ''}
                />
              );
            })}
          </div>
          <chc-promo variant="simple" color="default" size="default">
            <leaf-icon
              slot="icon"
              name="medical-receptionist"
              set="chc"
              label={compareContent.t('misc.phoneText')}
            ></leaf-icon>
            <h3 slot="heading">
              <strong>{HTMLReactParser(state.calloutInfoHeading)}</strong>
            </h3>
            <span slot="content">{compareContent.t('misc.calloutAvailability')}</span>
          </chc-promo>
        </>
      ) : (
        <>
          <leaf-spinner class="loading" role="alert" aria-live="assertive" size="large" mode="indeterminate">
            <span>{compareContent.t('misc.loading')}</span>
          </leaf-spinner>

          <div className="compare-plan-error" hidden>
            <leaf-alert variant="standalone" type="error">
              <h3 slot="heading">{compareContent.t('misc.errorFetchPlans')}</h3>
            </leaf-alert>
          </div>
        </>
      )}
    </div>
  );
};

export default ComparePlans;
